import React, { Component } from "react";

import MessageTemplate from "../MessageTemplate";
import message_template_btn from "./images/message_template_btn.png";

import {
  api_warden_message_template,
  api_evacuee_message_template
} from "../../../../config";
import axios from "axios";

import msg_app_sms from "./images/msg_app_sms.png";
import msg_in_app from "./images/msg_in_app.png";
import msg_ph_ph from "./images/msg_ph_ph.png";
import "./scss/messagebox.scss";

export const TEMP = {
  WARDEN: "Warden",
  EVACUEE: "Evacuee"
};

class MessageBox extends Component {
  textInputRef = React.createRef();
  messageboxRef = React.createRef();

  state = {
    text: "",
    templates: [],
    displayTemplates: false,
    focusText: false
  };

  componentDidMount() {
    this.getTemplates();

    this.textInputRef.addEventListener("keydown", this.autoExpand);
  }

  autoExpand = function() {
    var field = this;
    field.style.height = "5px";
    field.style.height = field.scrollHeight + 5 + "px";
  };

  isOutOfViewport = function(elem) {
    // Get element's bounding
    var bounding = elem.getBoundingClientRect();

    // Check if it's out of the viewport on each side
    var out = {};
    out.top = bounding.top < 0;
    out.left = bounding.left < 0;
    out.bottom =
      bounding.bottom >
      (window.innerHeight || document.documentElement.clientHeight);
    out.right =
      bounding.right >
      (window.innerWidth || document.documentElement.clientWidth);
    out.any = out.top || out.left || out.bottom || out.right;
    out.all = out.top && out.left && out.bottom && out.right;

    return out;
  };

  getTemplates = () => {
    let url = api_warden_message_template;
    if (this.props.type !== undefined && this.props.type === TEMP.EVACUEE) {
      url = api_evacuee_message_template;
    }

    axios
      .get(url)
      .then(resp => {
        this.setState({
          ...this.state,
          templates: [...resp.data.templates]
        });
      })
      .catch(err => {
        console.log(err);
      });
  };

  send = () => {
    if (this.state.text.length > 0) {
      this.setState({
        ...this.state,
        text: ""
      });
      this.props.send(this.state.text);
    } else {
      this.textInputRef.focus();
      this.setState({
        ...this.state,
        focusText: true
      });
    }
  };

  updateText = e => {
    // const textbox = this.textInputRef;
    // var messagebox = this.messageboxRef;
    // var positionInfo = messagebox.getBoundingClientRect();
    // var isOut = this.isOutOfViewport(textbox);
    // console.log(isOut.bottom);

    // if (isOut.bottom) {
    //   messagebox.style.height = positionInfo.height + 20 + "px";
    // }

    this.setState({
      ...this.state,
      text: e.target.value,
      focusText: false
    });
  };

  toggleMessageTemplateDisplay = () => {
    this.setState({
      ...this.state,
      displayTemplates: !this.state.displayTemplates
    });
  };

  /**
   * Handles the click event of a Template Item
   * Searches the templates for a specific id
   * and changes the state.text to template.text
   *
   * @param id (string)
   */
  insertTemplate = id => {
    if (this.state.templates.length > 0) {
      let templateText = this.state.templates.find(
        template => template.id === id
      ).text;
      if (templateText !== undefined) {
        this.setState({
          ...this.state,
          text: templateText,
          displayTemplates: false
        });
      }
    }
  };

  renderMessageTemplates = () => {
    return this.props.canSend && this.state.displayTemplates ? (
      <MessageTemplate
        templates={this.state.templates}
        insertTemplate={this.insertTemplate}
      />
    ) : null;
  };

  renderTextBox = () => {
    let className = "browser-default";

    if (this.state.focusText) {
      className = className + " messagebox__input--focus";
    } else {
      className = className + " messagebox__input--field";
    }

    return (
      <textarea
        className={className}
        ref={this.textboxRef}
        value={this.props.canSend ? this.state.text : ""}
        onChange={this.updateText}
        ref={el => (this.textInputRef = el)}
        disabled={this.props.loading || !this.props.canSend}
      />
    );
  };

  renderIndicator = () => {
    if (this.props.group !== undefined && this.props.group === false) {
      let image = null;
      let imgClass = "message_indicator_img";
      if (this.props.sms) {
        if (this.props.status.toLowerCase() === "on duty") {
          image = msg_in_app;
        } else {
          image = msg_app_sms;
        }
      } else {
        if (this.props.status.toLowerCase() === "on duty") {
          image = msg_in_app;
        } else {
          image = msg_ph_ph;
        }
      }

      return <img src={image} alt="message indicator" className={imgClass} />;
    } else {
      return null;
    }
  };

  render() {
    let templateBtnClass = "messagebox__templatebtn--img";

    if (!this.props.canSend) {
      templateBtnClass = templateBtnClass + " blacknwhite";
    }
    return (
      <React.Fragment>
        <div
          className="row messagebox__container"
          ref={el => (this.messageboxRef = el)}
        >
          <div className="col s12 message_indicator">
            {this.renderIndicator()}
          </div>
          {this.renderMessageTemplates()}
          <div className="col s12 messagebox__actionarea">
            <div className="col s2 messagebox__templatebtn">
              <img
                className={templateBtnClass}
                src={message_template_btn}
                alt="message template button"
                onClick={this.toggleMessageTemplateDisplay}
              />
            </div>
            <div className="col s8 messagebox__input">
              {this.renderTextBox()}
            </div>
            <div className="col s2 messagebox__input">
              <button
                className="messagebox__input--button"
                onClick={this.send}
                disabled={this.props.loading || !this.props.canSend}
              >
                send
              </button>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default MessageBox;
