import React from "react";
import CreateEventNotice from "../Utilities/CreateEventNotice";

const listLocations = props => {
  return props.locations && props.locations instanceof Array ? (
    <ul className="collection">
      {props.locations.map((location, index) => {
        return (
          <li
            key={location.building + location.site + index}
            className="collection-item blue-text text-lighten-1"
          >
            {location.site} - {location.building}
          </li>
        );
      })}
    </ul>
  ) : (
    <input
      type="text"
      name="location"
      id="location"
      className="report__form--textbox blue-text text-lighten-1"
      value="Not Applicable"
      readOnly
    />
  );
};

const listAreas = props => {
  if (props.area && props.area.locations instanceof Array) {
    let locations = props.area.locations.toString().split(",");
    return (
      <ul className="collection">
        {locations.map((location, index) => {
          return (
            <li
              key={location + " " + index}
              className="collection-item blue-text text-lighten-1"
            >
              {location}
            </li>
          );
        })}
      </ul>
    );
  } else {
    return (
      <input
        type="text"
        name="location"
        id="location"
        className="report__form--textbox blue-text text-lighten-1"
        value="Not Applicable"
        readOnly
      />
    );
  }
};

const ReportForm = props => {
  let loading = "Loading...";
  let current_events = loading;
  let role = loading;
  let area = loading;
  if (props.loading !== true) {
    current_events = props.current_events;
    role = props.role.Warden_Role;
    area = props.area;
  }

  return (
    <div className="container">
      <CreateEventNotice show={!props.active_event} />

      <form className="col s12 report__form">
        <div className="row mt-20">
          <div className="col s12">
            <label className="report__form--label">Current Event:</label>
            <input
              type="text"
              name="current_events"
              id="current_events"
              className="report__form--textbox red-text text-lighten-1"
              value={current_events}
              readOnly
            />
          </div>
        </div>

        <div className="row mt-20">
          <div className="col s12">
            <label className="report__form--label">Building(s) Included:</label>
            {props.loading ? (
              <ul className="collection">
                <li className="collection-item blue-text text-lighten-1">
                  {loading}
                </li>
              </ul>
            ) : (
              listLocations(props)
            )}
          </div>
        </div>

        <div className="row mt-10">
          <div className="col s12">
            <label className="report__form--label">Role:</label>
            <input
              type="text"
              name="role"
              id="role"
              className="report__form--textbox blue-text text-lighten-1"
              value={role || ""}
              readOnly
            />
          </div>
        </div>

        <div className="row mt-20">
          <div className="col s12">
            <label className="report__form--label">
              My Areas: <span className="report__form--domain">{area !== undefined ? area.domain+ " domain" : "NA"}</span>
            </label>
            {props.loading ? (
              <ul className="collection">
                <li className="collection-item blue-text text-lighten-1">
                  {loading}
                </li>
              </ul>
            ) : (
              listAreas(props)
            )}
          </div>
        </div>
      </form>
    </div>
  );
};

export default ReportForm;
