import React, { Component } from "react";

class Controls extends Component {
  renderBuildingControl = () => {
    return (
      <div className="row">
        <div className="col s3">
          <span className="label">Building:</span>
        </div>
        <div className="col s9">
          <select
            className="browser-default"
            name="building"
            value={this.props.input.building}
            onChange={this.props.handleChange}
          >
            {this.props.buildings.map(bldg => {
              return (
                <option key={bldg.id + bldg.name} value={bldg.id}>
                  {bldg.name}
                </option>
              );
            })}
          </select>
        </div>
      </div>
    );
  };

  renderFloorControl = () => {
    return (
      <div className="row">
        <div className="col s3">
          <span className="label">Floor:</span>
        </div>
        <div className="col s9">
          <select
            className="browser-default"
            name="floor"
            value={this.props.input.floor}
            onChange={this.props.handleChange}
          >
            {this.props.floors.map(floor => {
              return (
                <option key={floor.id + floor.name} value={floor.id}>
                  {floor.name}
                </option>
              );
            })}
          </select>
        </div>
      </div>
    );
  };

  renderStatusControl = () => {
    return (
      <div className="row">
        <div className="col s3">
          <span className="label">Status:</span>
        </div>
        <div className="col s9">
          <select
            className="browser-default"
            name="status"
            value={this.props.input.status}
            onChange={this.props.handleChange}
          >
            {this.props.floor_status.map(status => {
              return (
                <option key={status.id + status.name} value={status.id}>
                  {status.name}
                </option>
              );
            })}
          </select>
        </div>
      </div>
    );
  };

  renderSkeleton = () => {
    return (
      <div className="container evac__controls">
        <div className="skeleton skeleton__field br-10" />
        <div className="skeleton skeleton__field mt-10 br-10" />
        <div className="skeleton skeleton__field mt-10 br-10" />
        <div className="skeleton skeleton__field mt-10 br-10" />
      </div>
    );
  };

  render() {
    if (!this.props.loading) {
      return (
        <div className="container evac__controls">
          {this.renderBuildingControl()}
          {this.renderFloorControl()}
          {this.renderStatusControl()}
        </div>
      );
    } else {
      return <React.Fragment>{this.renderSkeleton()}</React.Fragment>;
    }
  }
}

export default Controls;
