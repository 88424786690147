import React, { Component } from "react";
import { Link } from "react-router-dom";

import { route } from "../../../Routes";

import swipedetect from "../../../Utilities/SwipeDetect";

class EvacueeListItem extends Component {
  swiperightClass = "swiperight";
  swipeleftClass = "swipeleft";
  swipedrightClass = "swipedright";
  swipedleftClass = "swipedleft";
  swipehintrightClass = "swipehint--right";
  swipehintleftClass = "swipehint--left";
  swiperighthintcontentClass = "swipehintright--content";
  swipelefthintcontentClass = "swipehintleft--content";

  componentDidMount() {
    var el = document.getElementById(this.props.evacuee.id + "content");

    window.addEventListener("scroll", function() {
      window.lastScrollTime = new Date().getTime();
    });

    swipedetect(el, swipedir => {
      var target = el;
      var swipeRightTarget = document.getElementById(
        this.props.evacuee.id + "swiperight"
      );
      var swipeLeftTarget = document.getElementById(
        this.props.evacuee.id + "swipeleft"
      );

      if (swipedir === "right") {
        /**
         * When they swipe right check if they are swiped left
         * if so just remove the swipe left classes
         * else
         * add the classes necessary to swipe right
         */
        if (target.classList.contains(this.swipeleftClass)) {
          target.classList.remove(this.swipeleftClass);
          swipeLeftTarget.classList.remove(this.swipedleftClass);
        } else {
          target.classList.add(this.swiperightClass);
          swipeRightTarget.classList.add(this.swipedrightClass);
        }
      } else if (swipedir === "left") {
        /**
         * When they swipe left check if they are swiped right
         * if so just remove the swipe right classes
         * else
         * add the classes necessary to swipe left
         */
        if (target.classList.contains(this.swiperightClass)) {
          target.classList.remove(this.swiperightClass);
          swipeRightTarget.classList.remove(this.swipedrightClass);
        } else {
          target.classList.add(this.swipeleftClass);
          swipeLeftTarget.classList.add(this.swipedleftClass);
        }
      } else if (swipedir === "none") {
        //give hints of swipes if they are not already swiped or not scrolling
        if (
          !this.is_scrolling() &&
          !swipeRightTarget.classList.contains(this.swipedrightClass) &&
          !swipeLeftTarget.classList.contains(this.swipedleftClass)
        ) {
          this.hintSwipeRight();
        }
      }
    });
  }

  closeSwipeRight = () => {
    var target = document.getElementById(this.props.evacuee.id + "content");
    var swipeRightTarget = document.getElementById(
      this.props.evacuee.id + "swiperight"
    );
    if (target.classList.contains(this.swiperightClass)) {
      target.classList.remove(this.swiperightClass);
      swipeRightTarget.classList.remove(this.swipedrightClass);
    }
  };

  setDanger = () => {
    this.closeSwipeRight();
    this.props.setDanger(this.props.evacuee.id);
  };

  setSafe = () => {
    this.closeSwipeRight();
    this.props.setSafe(this.props.evacuee.id);
  };

  setUnknown = () => {
    this.closeSwipeRight();
    this.props.setUnknown(this.props.evacuee.id);
  };

  is_scrolling = () => {
    return (
      window.lastScrollTime &&
      new Date().getTime() < window.lastScrollTime + 200
    );
  };

  renderSpecialTags = () => {
    return this.props.evacuee.special.map(tag => {
      if (tag.state) {
        let className = "tooltipped special__icon special__icon--" + tag.name;
        return (
          <span
            key={this.props.evacuee.id + " " + tag.name}
            className={className}
            title={tag.name}
          />
        );
      }
      return null;
    });
  };



  renderDetails = () => {
    return this.props.evacuee.special.map(tag => {
      if (tag.state) {
        let className = "detail__icon detail__icon--" + tag.name;
        let tagClass = tag.name;
        if(tag.name === "assist"){
          tagClass = tagClass+"-"+this.props.evacuee.status;
          className = className + "-"+this.props.evacuee.status;
        }


        if (tag.detail !== undefined && tag.detail !== null) {
          return (
            <span
              key={this.props.evacuee.id + " " + tag.name + " detail"}
              className={className}
            >
              <b className={tagClass}>{this.props.evacuee.status} on</b> {tag.detail}
            </span>
          );
        }
        return null;
      }
      return null;
    });
  };

  renderStatus = () => {
    let className =
      "listitem__status listitem__status--" + this.props.evacuee.status;

    return <div className={className}>{this.props.evacuee.status}</div>;
  };

  renderMessageIcon = () => {
    if (this.props.evacuee.message !== null) {
      let baseClass = "special__icon special__icon--";
      let noReplyClass = null;
      let unreadClass = null;
      if (this.props.evacuee.message.noreply) {
        noReplyClass = baseClass + "noreply";
      }
      if (this.props.evacuee.message.unread) {
        unreadClass = baseClass + "unread";
      }
      return (
        <React.Fragment>
          {noReplyClass !== null ? <span className={noReplyClass} /> : null}
          {unreadClass !== null ? <span className={unreadClass} /> : null}
        </React.Fragment>
      );
    }
    return null;
  };

  hintSwipeRight = () => {
    var target = document.getElementById(this.props.evacuee.id + "content");
    var swipeRightTarget = document.getElementById(
      this.props.evacuee.id + "swiperight"
    );
    var swipeLeftTarget = document.getElementById(
      this.props.evacuee.id + "swipeleft"
    );

    swipeRightTarget.classList.add(this.swipehintrightClass);
    target.classList.add(this.swiperighthintcontentClass);
    setTimeout(() => {
      swipeRightTarget.classList.remove(this.swipehintrightClass);
      target.classList.remove(this.swiperighthintcontentClass);

      swipeLeftTarget.classList.add(this.swipehintleftClass);
      target.classList.add(this.swipelefthintcontentClass);
      setTimeout(() => {
        swipeLeftTarget.classList.remove(this.swipehintleftClass);
        target.classList.remove(this.swipelefthintcontentClass);
      }, 150);
    }, 150);
  };

  renderContent = () => {
    return (
      <div className="listitem__content" id={this.props.evacuee.id + "content"}>
        <div className="col s12 evacsaftey__listitem--name">
          {this.props.evacuee.name}
        </div>
        <div className="row mb-5">
          <div className="col s5 evacsaftey__listitem--phone">
            {this.props.evacuee.phone}
          </div>
          <div className="col s7 evacsaftey__listitem--location">
            {this.props.evacuee.location}
          </div>
        </div>
        <div className="row mb-5">
          <div className="col s4 evacsaftey__listitem--status">
            {this.renderStatus()}
          </div>
          <div className="col s5 evacsaftey__listitem--special">
            {this.renderSpecialTags()}
          </div>
          <div className="col s3 evacsaftey__listitem--message">
            {this.renderMessageIcon()}
          </div>
        </div>
        <div className="col s12 evacsaftey__listitem--detail">
          {this.renderDetails()}
        </div>
      </div>
    );
  };

  renderSwipeRightContent = () => {
    let dangerDisabled = this.props.evacuee.status === "danger" ? true : false;
    let safeDisabled = this.props.evacuee.status === "safe" ? true : false;
    let unknownDisabled =
      this.props.evacuee.status === "unknown" ? true : false;

    return (
      <div
        className="listitem__swiperight"
        id={this.props.evacuee.id + "swiperight"}
      >
        {!dangerDisabled ? (
          <div className="col s6">
            <button
              className="listitem__swiperight--danger"
              onClick={this.setDanger}
            >
              danger
            </button>
          </div>
        ) : null}

        {!safeDisabled ? (
          <div className="col s6">
            <button
              className="listitem__swiperight--safe"
              onClick={this.setSafe}
            >
              safe
            </button>
          </div>
        ) : null}

        {!unknownDisabled ? (
          <div className="col s6">
            <button
              className="listitem__swiperight--unknown"
              onClick={this.setUnknown}
            >
              unknown
            </button>
          </div>
        ) : null}
      </div>
    );
  };

  renderSwipeLeftContent = () => {
    return (
      <div
        className="listitem__swipeleft"
        id={this.props.evacuee.id + "swipeleft"}
      >
        <div className="col s6">
          <a href={"tel:" + this.props.evacuee.phone}>
            <button className="listitem__swipeleft--call">
              <i className="material-icons">call</i>
            </button>
          </a>
        </div>

        <div className="col s6">
          <Link
            to={
              route.message.evacuee.link +
              "/" +
              this.props.evacuee.id +
              "?from=" +
              route.evacuee.safety.link
            }
          >
            <button className="listitem__swipeleft--message">
              <i className="material-icons">message</i>
            </button>
          </Link>
        </div>
      </div>
    );
  };

  render() {
    return (
      <React.Fragment>
        <div
          className="row evacsaftey__listitem"
          id={this.props.evacuee.id + "" + this.props.evacuee.name}
          onClick={this.disable}
        >
          {this.renderSwipeRightContent()}
          {this.renderContent()}
          {this.renderSwipeLeftContent()}
        </div>
      </React.Fragment>
    );
  }
}

export default EvacueeListItem;
