import React, { Component } from "react";
import {Link} from "react-router-dom";
import {route} from "../../Routes";

import { AREA, FILTER, MESSAGE } from "./index";

import icon_everyone from "./images/everyone.png";
import icon_myarea from "./images/myarea.png";
import icon_visitor from "./images/visitor.png";
import icon_messageall from "./images/messageall.png";
import icon_assist from "./images/assist.png";
import icon_showall from "./images/showall.png";
import icon_unread from "./images/unread.png";
import icon_noreply from "./images/noreply.png";
import icon_refusal from "./images/refusal.png";
import icon_building from "./images/building.png";

const AR = "area";
const MSG = "message";

class FilterControl extends Component {
  state = {
    showAreaOptions: false,
    area: AREA.MYAREA
  };

  toggleAreaOptions = () => {
    this.setState({
      ...this.state,
      showAreaOptions: !this.state.showAreaOptions
    });
  };

  setArea = area => {
    this.setState(
      {
        ...this.state,
        area
      },
      () => {
        this.props.setFilter(AR, this.state.area);
      }
    );
  };

  handleAreaChange = event => {
    switch (event.target.value) {
      case AREA.MYAREA:
        this.setArea(AREA.MYAREA);
        break;
      case AREA.EVERYONE:
        if (this.state.area !== null && this.state.area === AREA.EVERYONE) {
          this.setArea(AREA.MYAREA);
        } else {
          //check if
          this.setArea(AREA.EVERYONE);
        }
        break;
      default:
        //check if the state already has a building
        var bldgId = event.target.value;
        if (this.state.area instanceof Array) {
          var area = [...this.state.area];
          //if so check if the value is duplicate
          var idx = area.indexOf(bldgId);
          if (idx > -1) {
            //if so delete the duplicate value from the state array
            area.splice(idx, 1);
          } else {
            //if not insert the new value into the state array
            area.push(bldgId);
          }
        } else {
          //if area state selects all buildings
          //then when a building checkbox is clicked
          //uncheck only that building
          if (this.state.area === AREA.EVERYONE) {
            area = [];
            this.props.buildings.forEach(bldg => {
              if (bldgId !== bldg.buildingId.toString()) {
                area.push(bldg.buildingId.toString());
              }
            });
          } else {
            area = [event.target.value];
          }
        }
        //check to see if all the buildings are checked
        if (this.props.buildings.length === area.length) {
          //if so change the area to everyone
          area = AREA.EVERYONE;
        } else if (area.length === 0) {
          area = AREA.MYAREA;
        }
        this.setArea(area);

        break;
    }
  };

  renderAreaBtn = () => {
    let name = this.props.show.area;

    let icon = null;

    switch (this.props.show.area) {
      case AREA.MYAREA:
        icon = icon_myarea;
        break;
      case AREA.EVERYONE:
        icon = icon_everyone;
        break;
      default:
        icon = icon_building;
        name = "building";
        break;
    }

    return (
      <div className="icon">
        <img
          src={icon}
          alt={name + "button icon"}
          onClick={() => {
            this.toggleAreaOptions();
          }}
        />
        <div className="filtertext">{name}</div>
      </div>
    );
  };

  renderShowAllBtn = () => {
    let name = this.props.show.message;
    let icon = null;
    let changeTo = null;

    if (name === MESSAGE.SHOWALL) {
      icon = icon_showall;
      changeTo = MESSAGE.UNREAD;
    } else if (name === MESSAGE.UNREAD) {
      icon = icon_unread;
      changeTo = MESSAGE.NOREPLY;
    } else if (name === MESSAGE.NOREPLY) {
      icon = icon_noreply;
      changeTo = MESSAGE.SHOWALL;
    }
    return (
      <div className="icon">
        <img
          src={icon}
          alt={name + "button icon"}
          onClick={() => {
            this.props.setFilter(MSG, changeTo);
          }}
        />
        <div className="filtertext">{name}</div>
      </div>
    );
  };

  renderAssistBtn = () => {
    let disabled = !this.props.show.assist;
    let disabledClass = disabled ? "icon--disabled" : "";
    let textClass = "filtertext " + disabledClass;

    return (
      <div className="icon">
        <img
          src={icon_assist}
          alt="assist button icon"
          className={disabledClass}
          onClick={() => {
            this.props.setFilter(FILTER.ASSIST, !this.props.show.assist);
          }}
        />
        <div className={textClass}>assist</div>
      </div>
    );
  };

  renderRefusalBtn = () => {
    let disabled = !this.props.show.refusal;
    let disabledClass = disabled ? "icon--disabled" : "";
    let textClass = "filtertext " + disabledClass;
    return (
      <div className="icon">
        <img
          src={icon_refusal}
          alt="refusal button icon"
          className={disabledClass}
          onClick={() => {
            this.props.setFilter(FILTER.REFUSAL, !this.props.show.refusal);
          }}
        />
        <div className={textClass}>refusal</div>
      </div>
    );
  };

  renderVisitorBtn = () => {
    let disabled = !this.props.show.visitor;
    let disabledClass = disabled ? "icon--disabled" : "";
    let textClass = "filtertext " + disabledClass;

    return (
      <div className="icon">
        <img
          src={icon_visitor}
          alt="visitor button icon"
          className={disabledClass}
          onClick={() => {
            this.props.setFilter(FILTER.VISITOR, !this.props.show.visitor);
          }}
        />
        <div className={textClass}>visitor</div>
      </div>
    );
  };

  canMessageAll = event => {
    if(this.props.evacuees.length === 0 || !this.props.sms_allowed) {
      event.preventDefault();
    }
  }

  renderMessageAllBtn = () => {

    let className = "icon icon__messageall";
    if(this.props.evacuees.length === 0 || !this.props.sms_allowed) {
      className = className +" "+ className+"--disabled";
    }
    
    return (
      <div className={className}>
        <Link to={{
          pathname: route.message.evacueegroup.link,
          state: {
            filters: this.props.filters,
            searchText: this.props.searchText,
            sms_allowed: this.props.sms_allowed
          }
        }}
        onClick={this.canMessageAll}
        >
        <img
          src={icon_messageall}
          alt="message all button icon"
        />
        </Link>
      </div>
    );
  };

  renderAreaOptions = () => {
    return this.state.showAreaOptions ? (
      <React.Fragment>
        <div
          className="evacuee__areaoptions--overlay"
          onClick={this.toggleAreaOptions}
        />
        <div className="evacuee__areaoptions">
          <ul className="evacuee__areaoptions--list">
            <li className="evacuee__areaoptions--listitem">
              <label>
                <input
                  type="checkbox"
                  className="filled-in"
                  value={AREA.MYAREA}
                  onChange={e => this.handleAreaChange(e)}
                  checked={this.state.area === AREA.MYAREA ? true : false}
                />
                <span>My Area</span>
              </label>
            </li>
            <li className="evacuee__areaoptions--listsep" />
            <li className="evacuee__areaoptions--listitem">
              <label>
                <input
                  type="checkbox"
                  className="filled-in"
                  value={AREA.EVERYONE}
                  onChange={e => this.handleAreaChange(e)}
                  checked={this.state.area === AREA.EVERYONE ? true : false}
                />
                <span>All Buildings</span>
              </label>
            </li>
            <ul className="evacuee__areaoptions--childlist">
              {this.props.buildings.map(building => {
                return (
                  <li
                    className="evacuee__areaoptions--childlistitem"
                    key={building.buildingId + " bldg"}
                  >
                    <label>
                      <input
                        type="checkbox"
                        className="filled-in"
                        value={building.buildingId}
                        onChange={e => this.handleAreaChange(e)}
                        checked={
                          (this.state.area instanceof Array &&
                            this.state.area.indexOf(
                              building.buildingId.toString()
                            ) > -1) ||
                          this.state.area === AREA.EVERYONE
                            ? true
                            : false
                        }
                      />
                      <span>{building.name}</span>
                    </label>
                  </li>
                );
              })}
            </ul>
          </ul>
        </div>
      </React.Fragment>
    ) : (
      false
    );
  };

  render() {
    return (
      <div className="row evacsafety__filtercontrols">
        <div className="col s2">
          {this.renderAreaBtn()}
          {this.renderAreaOptions()}
        </div>
        <div className="col s2">{this.renderAssistBtn()}</div>
        <div className="col s2">{this.renderShowAllBtn()}</div>
        <div className="col s2">{this.renderVisitorBtn()}</div>
        <div className="col s2">{this.renderRefusalBtn()}</div>
        <div className="col s2">{this.renderMessageAllBtn()}</div>
      </div>
    );
  }
}

export default FilterControl;
