import React from "react";

const SearchBar = props => {
  const renderClearButton = () => {
    if (props.text.length > 0) {
      return (
        <button
          className="allmessage__searchBarInputClear"
          onClick={() => handleChange("")}
        >
          X
        </button>
      );
    } else {
      return (
        <button className="allmessage__searchBarInputClear" disabled>
          X
        </button>
      );
    }
  };

  const handleChange = (value) => {
    if(props.typeSearch && props.typeSearchLength !== undefined)
    {
      if(value.length >= props.typeSearchLength || value.length === 0) {
        props.updateText(value,props.filterData(value));
      }else{
        props.updateText(value);
      }
    }else{
      props.updateText(value);
    }
  }

  return (
    <div className="col s12 allmessage__searchBar">
      <input
        type="text"
        className="browser-default allmessage__searchBarInput"
        value={props.text}
        onChange={(e)=>handleChange(e.target.value)}
        placeholder="Search"
      />
      {renderClearButton()}
    </div>
  );
};

export default SearchBar;
