import React, { Component } from "react";

import FloorStatusList from "./FloorStatusList";

import EvacueeStatusSummary from "../../Utilities/EvacueeStatusSummary";
import BuildingSelect from "./BuildingSelect";
import Loader from "../../Utilities/Loader";

import {
  api_floor_evac_status_data,
  api_floor_evac_status_change
} from "../../../config";

import "./scss/floor-evac-status.scss";

import axios from "axios";

class FloorEvacuationStatus extends Component {
  state = {
    loading: true,
    loadSpinner: false,
    buildings: [],
    selectedBuilding: 0,
    evacSummary: {},
    floors: []
  };

  componentDidMount() {
    this.getInitialData();
  }

  loading = loading => {
    this.setState({
      ...this.state,
      loading
    });
  };

  getInitialData = () => {
    axios
      .get(api_floor_evac_status_data)
      .then(res => {
        this.setState({
          ...this.state,
          loading: false,
          ...res.data
        });
      })
      .catch(err => {
        this.loading(false);
      });
  };

  changeSelectedBuilding = buildingId => {
    this.setState({
      ...this.state,
      loadSpinner: true
    });
    let parsedBuildingId = parseInt(buildingId, 10);
    if (!isNaN(parsedBuildingId)) {
      axios
        .get(api_floor_evac_status_change, {
          params: {
            buildingId
          }
        })
        .then(res => {
          this.setState({
            ...this.state,
            loadSpinner: false,
            selectedBuilding: buildingId,
            ...res.data
          });
        })
        .catch(err => {
          this.setState({
            ...this.state,
            loadSpinner: false
          });
        });
    }
  };

  renderLoadState = () => {
    return (
      <div className="container">
        <div className="skeleton skeleton__field br-10" />
        <div className="skeleton skeleton__field br-10 mt-10" />
        <div className="skeleton skeleton__table br-10 mt-10" />
        <div className="skeleton skeleton__table--alternate br-10 mt-10" />
      </div>
    );
  };

  renderIfNotLoading = () => {
    if (!this.state.loading) {
      return (
        <React.Fragment>
          <BuildingSelect
            buildings={this.state.buildings}
            selectedBuilding={this.state.selectedBuilding}
            changeSelected={this.changeSelectedBuilding}
          />
          <EvacueeStatusSummary showBar={true} summary={this.state.evacSummary} />
          <FloorStatusList floors={this.state.floors} />
        </React.Fragment>
      );
    } else {
      return this.renderLoadState();
    }
  };

  render() {
    return (
      <div className="container extended mt-20">
        <Loader loading={this.state.loadSpinner} />
        {this.renderIfNotLoading()}
      </div>
    );
  }
}

export default FloorEvacuationStatus;
