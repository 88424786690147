import React, { Component } from "react";

class MessageFilterSelector extends Component {
  state = {
    expand: false
  };

  isChecked = value => {
    if (this.state.selected.includes(value)) {
      return true;
    }
    return false;
  };

  toggleExpand = () => {
    this.setState(
      {
        ...this.state,
        expand: !this.state.expand
      },
      () => {
        if (!this.state.expand && this.props.onClose !== undefined) {
          this.props.toggleTab(this.props.label);
          this.props.onClose();
        } else {
          this.props.toggleTab(this.props.label);
        }
      }
    );
  };

  renderSelectionList = () => {
    return (
      this.state.expand &&
      this.props.selectorContent.map(content => {
        let id = content.id !== undefined ? content.id : content.name;
        return (
          <div
            key={content.id + content.name + "__" + this.props.label}
            className="col s12 messageFilterSelector__selection--child"
          >
            <label>
              <input
                type="checkbox"
                className="filled-in"
                value={id}
                onChange={() => this.props.update(id)}
                checked={
                  this.props.selected.includes("all") ||
                  this.props.selected.includes(id.toString())
                }
              />
              <span className="messageFilterSelector__text">
                {content.name}
              </span>
            </label>
          </div>
        );
      })
    );
  };

  renderSelectedText = () => {
    if (this.props.selected.includes("all")) {
      return "all";
    } else if (this.props.selected.length > 0) {
      return this.props.selected.length + " selected";
    } else {
      return "all";
    }
  };

  render() {
    let className = "col s12 messageFilterSelector";
    if (this.state.expand) {
      className = className + "--expanded";
    }
    return (
      <div className="row messageFilterSelector__container">
        <div className={className} onClick={this.toggleExpand}>
          <span className="messageFilterSelector__label">
            {this.props.label + ":"}
          </span>
          <span className="messageFilterSelector__selection--hint">
            {this.renderSelectedText()}
          </span>
        </div>
        {this.state.expand && (
          <div className="col s12 messageFilterSelector__selection--parent">
            <label>
              <input
                type="checkbox"
                className="filled-in"
                value="all"
                onChange={e => this.props.update(e.target.value)}
                checked={this.props.selected.includes("all")}
              />
              <span className="messageFilterSelector__text">all</span>
            </label>
          </div>
        )}
        {this.renderSelectionList()}
      </div>
    );
  }
}

export default MessageFilterSelector;
