import React, { Component } from "react";

import refusal_black from "../images/refusal_red.png";


class PersonListItem extends Component {
  renderSwitch = () => {
    let leverClassName = "lever";
    let reportedText = "Reported";
    if (this.props.info.reported) {
      leverClassName += " red reported";
    }

    if (!this.props.info.reported) {
      reportedText = "";
    }

    return (
      <React.Fragment>
        <div className="col s12 reported__switch">
          <div className="switch">
            <label>
              <input
                type="checkbox"
                checked={this.props.info.reported}
                onChange={() => {
                  this.props.handleReportedChange(this.props.info.id);
                }}
              />
              <span className={leverClassName} />
            </label>
          </div>
        </div>
        <div className="col s12">
          <div className="reported__text red-text">{reportedText}</div>
        </div>
      </React.Fragment>
    );
  };

  renderR2EButton = () => {
    let btnClass = "reported__button";
    if (this.props.info.reported) {
      btnClass = btnClass + "--active";
    } else {
      btnClass = btnClass + "--inactive";
    }

    return (
      <React.Fragment>
        <div className="col s12 reported__button">
          <button
            className={btnClass}
            onClick={() => {
              this.props.handleReportedChange(this.props.info.id);
            }}
          >
            <img src={refusal_black} alt="refusal img"/>

          </button>
        </div>
      </React.Fragment>
    );
  };

  render() {
    return (
      <div className="row person__list--item">
        <div className="col s9">
          <div className="person__info">
            <div className="col s12">
              <span className="person__name truncate">
                {this.props.info.name}
              </span>
            </div>
            <div className="col s12">
              <span className="person__phone">{this.props.info.phone}</span>
            </div>
            <div className="col s12">
              <span className="person__location">
                {this.props.info.location}
              </span>
            </div>
          </div>
        </div>
        <div className="col s3 person__reported">{this.renderR2EButton()}</div>
      </div>
    );
  }
}

export default PersonListItem;
