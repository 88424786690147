import React, { Component } from "react";
import axios from "axios";
import ls from "local-storage";
import { withRouter } from "react-router-dom";
import Loader from "../../Utilities/Loader";
import {
  CancelTokenSource,
  api_logout_url,
  api_token_validate
} from "../../../config";

import { route } from "../../Routes";

export default GuardedComponent => {
  class onlyLoggedIn extends Component {
    constructor(props, context) {
      super(props, context);
      console.log("---onlyLoggedIn---");
    }

    state = {
      spinnerLoading: true,
      loggedIn: false,
      active_event: false,
      popup: {
        show: false,
        message: ""
      }
    };

    spinnerLoading = loading => {
      this.setState({
        ...this.state,
        spinnerLoading: loading
      });
    };

    isLoggedIn = () => {
      if (ls.get("auth") === null) {
        this.props.history.push(route.login.link);
      } else {
        this.spinnerLoading(true);
        axios
          .get(api_token_validate, {
            cancelToken: CancelTokenSource.token,
          })
          .then(resp => {
            if (resp.data !== null) {
              if (
                resp.data.message !== undefined &&
                resp.data.message === "Authenticated"
              ) {
                this.setState({
                  ...this.state,
                  spinnerLoading: false,
                  loggedIn: true,
                  active_event: true,
                  popup: {
                    ...this.state.popup,
                    show: false
                  }
                });
              } else if (
                resp.data.status !== undefined &&
                resp.data.status === "Unauthorized"
              ) {
                this.setState({
                  ...this.state,
                  spinnerLoading: false,
                  loggedIn: true,
                  active_event: false,
                  popup: {
                    show: true,
                    title: "Event Ended",
                    message: resp.data.message
                  }
                });
              } else if (
                resp.data.message !== undefined &&
                resp.data.message === "Unauthenticated"
              ) {
                this.setState({
                  ...this.state,
                  spinnerLoading: false,
                  loggedIn: false,
                  active_event: false,
                  popup: {
                    show: true,
                    title: "Logged out",
                    message: "Your session has ended, please login again"
                  }
                });
              }
            } else {
              this.setState(
                {
                  ...this.state,
                  spinnerLoading: false
                },
                () => {
                  ls.clear();
                  this.props.history.push(route.login.link);
                }
              );
            }
          })
          .catch(err => {
            if (axios.isCancel(err)) {
              console.log("Request Canceled", err.mesage);
            }
            ls.clear();
            this.props.history.push(route.login.link);
          });
      }
    };

    /**
     * This method logs user out from the application
     * by deleting ther access token from the server
     * and also deleting local storage data
     */
    handleLogout = () => {
      axios
        .delete(api_logout_url)
        .then(resp => {
          ls.clear();
          this.props.history.push(route.login.link);
        })
        .catch(err => {
          console.log(err);
        });
    };

    componentDidMount = () => {
      this.isLoggedIn();
    };

    renderPopup = () => {
      if (this.state.popup.show) {
        return (
          <div className="overlay_container">
            <div className="overlay_box">
              <div className="overlay_message blue-grey-text darken-3">
                {this.state.popup.message}
              </div>
              <div className="overlay_button_ok">
                <button onClick={this.handleLogout}>Logout</button>
              </div>
            </div>
          </div>
        );
      }
      return null;
    };

    render() {
      if (this.state.spinnerLoading) {
        return <Loader loading={true} />;
      }
      if (this.state.loggedIn && this.state.active_event) {
        return <GuardedComponent {...this.props} />;
      } else {
        return <React.Fragment>{this.renderPopup()}</React.Fragment>;
      }
    }
  }

  return withRouter(onlyLoggedIn);
};
