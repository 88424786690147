import React from "react";
import { Link } from "react-router-dom";
import "./scss/recipientinfo.scss";

import msg_back from "../images/msg_back_btn.png";

export const INFO_TYPE = {
  WARDEN: "warden",
  EVACUEE: "evacuee"
};

const RecipientInfo = props => {
  const renderRecipientStatus = () => {
    let status = props.recipient.status.toUpperCase();
    let statusClass = "green--bg";
    if (status === "UNKNOWN" || status === "NOT AVAILABLE") {
      statusClass = "yellow--bg";
    } else if (status === "DANGER" || status === "OFF DUTY") {
      statusClass = "red--bg";
    }

    let className = "recipient__status--badge " + statusClass;

    return <span className={className}>{props.recipient.status}</span>;
  };

  const renderInfo = () => {
    if (!props.loading) {
      return (
        <div className="col s12 recipient__info">
          <div className="col s9 recipient__name">{props.recipient.name}</div>
          <div className="col s3 recipient__back">
            <Link to={props.back}>
              <img src={msg_back} className="recipient__back--btn" alt="back button" />
            </Link>
          </div>
          {props.type === INFO_TYPE.WARDEN ? (
            <div className="col s12 recipient__role">
              {props.recipient.role}
            </div>
          ) : null}
          <div className="col s6 recipient__contact">
            {props.recipient.phone}
          </div>
          <div className="col s6 recipient__location">
            {props.recipient.location}
          </div>
          <div className="col s12 recipient__status">
            {renderRecipientStatus()}
          </div>
        </div>
      );
    } else {
      return (
        <div className="col s12 recipient__info">
          <div className="col s12 skeleton mt-10">
            <div className="col s12 skeleton skeleton__field" />
            <div className="col s12 skeleton skeleton__field" />
          </div>
        </div>
      );
    }
  };

  return (
    <div className="row message__recipient">
      {/* <div className="col s2 recipient__image"></div> */}
      {renderInfo()}
    </div>
  );
};

export default RecipientInfo;
