import React, { Component } from "react";
import Header from "./Header";
import EmailLoginForm from "./Email";
import PeerLoginForm from "./Peer";
import LoginOptions from "./LoginOptions";
import ForgotOptions from "./ForgotOptions";
import Modal from "../Utilities/Modal";
import {
  api_user_url,
  api_email_login_url,
  api_peer_login_url,
  api_new_session_url
} from "../../config";

import axios from "axios";
import ls from "local-storage";

import "./scss/login.scss";

class Login extends Component {
  state = {
    me: {
      client_uid: "",
      firstname: "",
      lastname: "",
      email: "",
      mobile: "",
      client_master: "",
      person_uid: "",
      primary_floor_uid: ""
    },
    credentials: {
      email: "",
      password: ""
    },
    error: {
      error: "",
      message: "",
      show: false,
      login_btn: false
    },
    peer_emai: "",
    showEmailForm: true,
    loading: false
  };

  /**
   * This methods changes the state of application if it
   * is loading some data
   */
  loading = isLoading => {
    this.setState({
      ...this.state,
      loading: isLoading
    });
  };

  /**
   * This method makes sure that we only call the
   * getUserData method if we have the access_token
   * in local storage
   */
  getUserFromToken = (peer = false) => {
    if (ls.get("access_token") !== null) {
      this.getUserData(peer);
    }
  };

  /**
   * The methods gets person data and stores
   * it in the state and passes it to its parent
   * by using prop and udates the global state of
   * "me" and redirects the user to /reportin
   */
  getUserData = (peer = false) => {
    axios
      .get(api_user_url, {
        headers: {
          Authorization: ls.get("auth").access_token
        }
      })
      .then(resp => {
        this.setState({
          ...this.state,
          me: {
            firstname: resp.data.Firstname,
            lastname: resp.data.Lastname,
            email: resp.data.Email,
            mobile: resp.data.Mobile,
            client_master: resp.data.Is_Client_Master,
            client_uid: resp.data.Client_UID,
            primary_floor_uid: resp.data.Primary_Location_Floor_UID
          }
        });
        this.props.updateMe(this.state.me);
        if (!peer) {
          this.props.history.push("/reportin");
        } else {
          ls.set("peer_email", this.state.peer_email);
          this.props.history.push("/reportin");
        }
      })
      .catch(err => {
        this.setState({
          ...this.state,
          error: {
            error: err.error,
            message: err.message
          }
        });
      });
  };

  /**
   * This method takes in credentials (email and password)
   * as a argument and tries to log in the person using it
   * if they login successfully, it stores the access_token
   * and other information that is returned from the server
   * in local storage
   */
  emailLogin = credentials => {
    this.setState({
      ...this.state,
      credentials,
      loading: true,
    });
    if (this.validateEmailLoginCredentials(credentials)) {
      axios
        .post(api_email_login_url, credentials)
        .then(resp => {
          if (resp.data.error) {
            if (resp.data.error === "already_signed_in") {
              this.setState({
                ...this.state,
                error: {
                  error: resp.data.error,
                  message: resp.data.message,
                  show: true,
                  login_btn: true
                }
              });
            } else {
              this.setState({
                ...this.state,
                error: {
                  error: resp.data.error,
                  message: resp.data.message,
                  show: true,
                  login_btn: false
                }
              });
            }

            this.loading(false);
          } else {
            let auth = {
              access_token: resp.data.token_type + " " + resp.data.access_token,
              refresh_token: resp.data.refresh_token,
              grant: resp.data.token_grant,
              expires_in: resp.data.expires_in
            };
            ls.set("auth", auth);
            this.loading(false);
            this.getUserData();
          }
        })
        .catch(err => {
          console.log(err);
          this.loading(false);
        });
    }
  };

  validateEmailLoginCredentials = credentials => {
    let error = null;
    let message = null;
    if (credentials.email.length > 0) {
      if (/^.+@.+\..+/.test(credentials.email)) {
        if (credentials.password.length >= 6) {
          return true;
        } else {
          error = "invalid_password";
          message = "Password must be atleast 6 characters long.";
        }
      } else {
        error = "invalid_email";
        message = "The email format is invalid";
      }
    } else {
      error = "invalid_email";
      message = "Please input a email";
    }
    this.setState({
      ...this.state,
      error: {
        ...this.state.error,
        error,
        message,
        show: true,
        login_btn: false
      }
    });
    return false;
  };

  changePeerEmail = peer_email => {
    this.setState({
      ...this.state,
      peer_email: peer_email
    });
  };

  peerLogin = credentials => {
    this.setState({
      ...this.state,
      credentials,
      loading: true,
    });
    axios
      .post(api_peer_login_url, credentials)
      .then(resp => {
        if (resp.data.error) {
          if (resp.data.error === "already_signed_in") {
            this.setState({
              ...this.state,
              error: {
                error: resp.data.error,
                message: resp.data.message,
                show: true,
                login_btn: true
              }
            });
          } else {
            this.setState({
              ...this.state,
              error: {
                error: resp.data.error,
                message: resp.data.message,
                show: true,
                login_btn: false
              }
            });
          }
          this.loading(false);
        } else {
          let auth = {
            access_token: resp.data.token_type + " " + resp.data.access_token,
            grant: resp.data.token_grant,
            expires_in: resp.data.expires_in
          };
          ls.set("auth", auth);
          this.loading(false);
          this.getUserData(true);
        }
      })
      .catch(err => {
        console.log(err);
        this.loading(false);
      });
  };

  /**
   * This method is called if the user is already signed in
   * and gets a wants to start a new session.
   */
  startNewSession = () => {
    this.loading(true);
    this.closeErrorPopup();
    axios
      .post(api_new_session_url, this.state.credentials, null)
      .then(resp => {
        if (resp.data.error) {
          this.setState({
            ...this.state,
            error: {
              error: resp.data.error,
              message: resp.data.message,
              show: true,
              login_btn: false
            }
          });
          this.loading(false);
        } else {
          let auth = {
            access_token: resp.data.token_type + " " + resp.data.access_token,
            refresh_token: resp.data.refresh_token,
            grant: resp.data.token_grant,
            expires_in: resp.data.expires_in
          };
          ls.set("auth", auth);
          this.loading(false);
          this.getUserData();
        }
      })
      .catch(err => {
        console.log(err.data);
        this.loading(false);
      });
  };

  /**
   * Method that determines weather to show Email
   * Login Form or the Peer Login Form
   */
  showForm = form => {
    if (form === "email") {
      this.setState({
        showEmailForm: true
      });
    } else {
      this.setState({
        showEmailForm: false
      });
    }
  };

  /**
   * Method that handles closing of the
   * Error Modal box
   */
  closeErrorPopup = () => {
    this.setState({
      ...this.state,
      error: {
        ...this.state.error,
        show: false
      }
    });
  };

  renderModalBtn = () => {
    if (this.state.error.login_btn) {
      return (
        <div className="row">
          <div className="actions col s6">
            <button
              className="btn-small blue darken-2 full-ok"
              onClick={this.startNewSession}
              disabled={this.state.loading}
            >
              login
            </button>
          </div>
          <div className="actions col s6">
            <button
              className="btn-small blue darken-2 full-ok"
              onClick={this.closeErrorPopup}
            >
              cancel
            </button>
          </div>
        </div>
      );
    } else {
      return (
        <div className="row">
          <div className="actions col s12">
            <button
              className="btn-small blue darken-2 full-ok"
              onClick={this.closeErrorPopup}
            >
              Ok
            </button>
          </div>
        </div>
      );
    }
  };

  render() {
    return (
      <React.Fragment>
        <Modal
          id="errorPopup"
          show={this.state.error.show}
          close={this.closeErrorPopup}
        >
          <div className="error">
            <p className="center-align">
              {this.state.error.error
                ? this.state.error.error.replace(/_/g, " ")
                : null}
            </p>
          </div>
          <div className="message col s12 valign-wrapper">
            <p className="center-text">
              {this.state.error.message} <br />
              {this.state.error.login_btn ? (
                <span className="login_btn_warning">
                  ( Pressing Login Button will end any other sessions. )
                </span>
              ) : null}
            </p>
          </div>
          {this.renderModalBtn()}
        </Modal>

        <Header />
        <div className="login">
          <div className="container">
            {this.state.showEmailForm ? (
              <EmailLoginForm
                login={this.emailLogin}
                getUserData={this.getUserFromToken}
                loading={this.state.loading}
              />
            ) : (
              <PeerLoginForm
                login={this.peerLogin}
                getUserData={this.getUserFromToken}
                loading={this.state.loading}
                isLoading={this.state.loading}
                changePeerEmail={this.changePeerEmail}
              />
            )}
            <LoginOptions showForm={this.showForm} />
            <ForgotOptions />
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default Login;
