import React from "react";

const BuildingSelect = props => {
  const renderBuildings = () => {
    return props.buildings.map(bldg => {
      return (
        <option key={bldg.id} value={bldg.id}>
          {bldg.name}
        </option>
      );
    });
  };

  const handleBuildingChange = event => {
    props.changeSelected(event.target.value);
  };

  return (
    <div className="row floorevacstatus">
      <div className="col s3 floorevacstatus__label">Building : </div>
      <div className="col s9">
        <select
          className="browser-default floorevacstatus__select"
          defaultValue={props.selectedBuilding}
          onChange={handleBuildingChange}
        >
          {renderBuildings()}
        </select>
      </div>
    </div>
  );
};

export default BuildingSelect;
