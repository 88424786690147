import React, { Component } from "react";

import FloorStatusListItem from "./FloorStatusListItem";

import peep_img from "../../../Evacuee/Safety/images/special_assist.png";
import refusal_img from "../../../Evacuee/Safety/images/special_refusal.png";

class FloorStatusList extends Component {
  renderListHead = () => {
    return (
      <div className="row floorevacstatus__listhead">
        <div className="col s4">Level</div>
        <div className="col s4">Status</div>
        <div className="col s4 floorevacstatus__listcounts">
          <div className="col s6">
            <span className="floorevacstatus__listsafe">Safe</span>
          </div>
          <div className="col s3">
            <img className="floorevacstatus__listimg" src={peep_img} alt="peep image" />
          </div>
          <div className="col s3">
            <img className="floorevacstatus__listimg" src={refusal_img} alt="refusal image" />
          </div>
        </div>
      </div>
    );
  };

  renderFloorListItems = () => {
    return this.props.floors.map(floor => {
      return (
        <FloorStatusListItem key={"floorlistitem" + floor.id} floor={floor} />
      );
    });
  };

  render() {
    return (
      <div className="floorevacstatus__list">
        {this.renderListHead()}
        {this.renderFloorListItems()}
      </div>
    );
  }
}

export default FloorStatusList;
