import React, { Component } from "react";

import PeepListItem from "./PeepListItem";
import {
  api_floor_evacuation_peep_search,
  api_floor_evacuation_peep_data
} from "../../../../config";

import axios from "axios";

class PeepList extends Component {
  state = {
    show: true,
    search: ""
  };

  componentDidMount() {
    if (!this.props.peep.length) {
      this.setState({
        show: false
      });
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.peep.length !== this.props.peep.length) {
      if (!this.props.peep.length && this.state.search.length === 0) {
        this.setState({
          show: false
        });
      } else {
        this.setState({
          show: true
        });
      }
    }
    if (prevState.search.length !== this.state.search.length) {
      if (this.state.search.length === 0) {
        this.loadSelectedFloorPeep();
      }
    }
  }

  toggleCollapse = () => {
    this.setState({
      ...this.state,
      show: !this.state.show
    });
  };

  loadSelectedFloorPeep = () => {
    let searchUrl =
      api_floor_evacuation_peep_data + "/" + this.props.selectedFloor;
    axios
      .get(searchUrl)
      .then(resp => {
        this.props.setPeepsList(resp.data);
      })
      .catch(err => {
        console.log(err);
      });
  };

  searchEvacuees = () => {
    let searchUrl = api_floor_evacuation_peep_search + "/" + this.state.search;

    axios
      .get(searchUrl)
      .then(resp => {
        this.props.setPeepsList(resp.data);
      })
      .catch(err => {
        console.log(err);
      });
  };

  handleSearchChange = e => {
    this.setState(
      {
        ...this.state,
        search: e.target.value
      },
      () => {
        if (this.state.search.length >= 3) {
          this.searchEvacuees();
        }
      }
    );
  };

  renderCollapseArrow = () => {
    let arrow = "keyboard_arrow_down";
    if (!this.state.show) {
      arrow = "keyboard_arrow_up";
    }
    return arrow;
  };

  renderPeepCount = () => {
    return this.props.peep.length;
  };

  renderListHeader = () => {
    return (
      <div className="list__header peep__header" onClick={this.toggleCollapse}>
        <div className="container">
          <div className="row">
            <div className="col s2">
              <span className="header__icon">
                <i className="material-icons">accessible</i>
              </span>
            </div>
            <div className="col s7">
              <span className="header__title">People with Disability</span>
            </div>
            <div className="col s2">
              <span className="header__count">{this.renderPeepCount()}</span>
            </div>
            <div className="col s1">
              <span className="header__collapsible">
                <i className="material-icons">{this.renderCollapseArrow()}</i>
              </span>
            </div>
          </div>
        </div>
      </div>
    );
  };

  renderPeepList = () => {
    let className = "container peep__list";
    if (!this.state.show) {
      className += " hide";
    }

    let errorMsg =
      this.state.search.length >= 3
        ? "No PEEPs Found..."
        : "No PEEPs on this floor";

    return (
      <div className={className}>
        {this.props.peep.length > 0 ? (
          <React.Fragment>
            <div className="container peep__list--note">
              <b>Note</b>: Only record the Safety Status of PEEP’s who will remain on
              this floor.
            </div>
            {this.props.peep.map(evacuee => {
              return (
                <PeepListItem
                  key={evacuee.id + evacuee.name + " peep"}
                  info={evacuee}
                  pwdmi_statuses={this.props.pwdmi_statuses}
                  handlePeepStatusChange={this.props.handlePeepStatusChange}
                />
              );
            })}
          </React.Fragment>
        ) : (
          <div className="no__peep">{errorMsg}</div>
        )}
      </div>
    );
  };

  renderSkeleton = () => {
    return (
      <div className="container mt-20">
        <div className="skeleton skeleton__table" />
      </div>
    );
  };

  renderPeepSearch() {
    let className = "container person__search";
    if (!this.state.show) {
      className += " hide";
    }
    return (
      <div className={className}>
        <input
          type="text"
          className="browser-default input__search"
          placeholder="search peep"
          value={this.state.search}
          onChange={this.handleSearchChange}
        />
      </div>
    );
  }

  render() {
    if (!this.props.loading && !this.props.onlyPeepLoad) {
      return (
        <React.Fragment>
          {this.renderListHeader()}
          {this.renderPeepSearch()}
          {this.renderPeepList()}
        </React.Fragment>
      );
    } else {
      return <React.Fragment>{this.renderSkeleton()}</React.Fragment>;
    }
  }
}

export default PeepList;
