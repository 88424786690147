import React, { Component } from "react";

import SearchBar from "./SearchBar";
import MessengerList from "./MessengerList";
import MessageFilterSelector from "./MessageFilterSelector";
import ActionBar from "./ActionBar";

import Loader from "../../Utilities/Loader";

import "./scss/allmessage.scss";

import {
  api_message_data,
  api_message_filtered_data,
  api_cspd_filtered_data,
  CancelTokenSource
} from "../../../config";
import axios from "axios";

const VIEW = {
  CSPD: "correspondence",
  FLTR: "filters"
};

export const LABEL = {
  BLDG: "Building(s)",
  ROLE: "Role(s)",
  STATUS: "Status"
};

const POLLING_DELAY = 10000; // 10s


export const STATUS_LIST = [
  {
    name: "on duty"
  },
  {
    name: "off duty"
  },
  {
    name: "unknown"
  },
  {
    name: "not available"
  }
];

class AllMessage extends Component {
  pollTimer = null;

  state = {
    loader: false,
    view: VIEW.FLTR,
    tabs: {
      building: {
        open: false,
      },
      role:{
        open: false,
      },
      status:{
        open: false
      }
    },
    buildings: [
      {
        id: "1",
        name: "Tower 1"
      },
      {
        id: "2",
        name: "Tower 2"
      },
      {
        id: "3",
        name: "Auditorium"
      }
    ],
    roles: [
      {
        id: "1",
        name: "Chief Warden"
      },
      {
        id: "2",
        name: "Floor Warden"
      },
      {
        id: "3",
        name: "Area Warden"
      }
    ],
    filters: {
      searchText: "",
      buildings: ["all"],
      roles: ["all"],
      status: ["all"]
    },
    cspdList: [],
    messengerList: [],
    sms_allowed: false
  };

  componentDidMount() {
    this.getInitialState();

    this.pollTimer = setInterval(() => {
      this.pollForUnreadMessages();
    }, POLLING_DELAY);
  }

  componentWillUnmount() {
    clearInterval(this.pollTimer);
  }

  pollForUnreadMessages = () => {
    if (this.state.view === VIEW.CSPD) {
      this.getCspdFilteredData(this.state.filters.searchText, false);
    } else if (this.state.view === VIEW.FLTR) {
      this.getFilteredData(this.state.filters.searchText,false);
    }
  };

  loader = loader => {
    this.setState({
      ...this.state,
      loader
    });
  };

  /**
   * Gets the initial state of the component
   */
  getInitialState = () => {
    this.loader(true);
    axios
      .get(api_message_data, {
        cancelToken: CancelTokenSource.token
      })
      .then(resp => {
        this.setState({
          ...this.state,
          ...resp.data,
          loader: false
        });
      })
      .catch(err => {
        if(axios.isCancel(err)) {
          console.log('Request Canceled',err.mesage);
        }
      });
  };

  /**
   * This method is called when we apply filters
   */
  getFilteredData = (searchText,loader = true) => {
    if (loader) {
      this.loader(true);
    }

    let params = null;
    let buildings =
      this.state.filters.buildings.length > 0
        ? [...this.state.filters.buildings]
        : ["all"];
    let roles =
      this.state.filters.roles.length > 0
        ? [...this.state.filters.roles]
        : ["all"];
    let status =
      this.state.filters.status.length > 0
        ? [...this.state.filters.status]
        : ["all"];
    if (this.state.filters.searchText.length === 0) {
      params = {
        b: buildings,
        r: roles,
        s: status
      };
    } else {
      params = {
        b: buildings, //buildings
        r: roles, //roles
        s: status, //status
        st: searchText !== undefined ? searchText : this.state.filters.searchText //searchText
      };
    }

    axios
      .get(api_message_filtered_data, {
        cancelToken: CancelTokenSource.token,
        params: {
          ...params
        }
      })
      .then(resp => {
        loader
          ? this.setState({
              ...this.state,
              disableAllMessage: false,
              messengerList: [...resp.data.messengerList],
              filters: {
                ...this.state.filters,
                searchText: searchText !== undefined ? searchText : this.state.filters.searchText
              },
              loader: false
            })
          : this.setState({
              ...this.state,
              disableAllMessage: false,
              messengerList: [...resp.data.messengerList],
              filters: {
                ...this.state.filters,
                searchText: searchText !== undefined ? searchText : this.state.filters.searchText
              },
            });
      })
      .catch(err => {
        if (axios.isCancel(err)) {
          console.log("Request Cancelled", err.message);
        } else {
          if (loader) {
            this.loader(false);
          }
        }
      });
  };

  /**
   * This method fetches data for correspondence list
   * It takes in a search text as a parameter
   */
  getCspdFilteredData = (searchText, loader = true) => {
    if (loader) {
      this.loader(true);
    }

    axios
      .get(api_cspd_filtered_data, {
        cancelToken: CancelTokenSource.token,
        params: {
          searchText: searchText
        }
      })
      .then(resp => {
        loader
          ? this.setState({
              ...this.state,
              filters: {
                ...this.state.filters,
                searchText: searchText
              },
              cspdList: [...resp.data.cspdList],
              loader: false
            })
          : this.setState({
              ...this.state,
              filters: {
                ...this.state.filters,
                searchText: searchText
              },
              cspdList: [...resp.data.cspdList]
            });
      })
      .catch(err => {
        if (axios.isCancel(err)) {
          console.log("Request canceled", err.message);
        } else {
          if (loader) {
            this.loader(false);
          }
          console.log(err);
        }
      });
  };

  /**
   * Switches between Filter and Correspondence view
   */
  updateView = view => {
    this.setState({
      ...this.state,
      view,
    },()=>{
      if(view === VIEW.CSPD) {
        this.getCspdFilteredData(this.state.filters.searchText);
      }
      else if(view === VIEW.FLTR) {
        this.getFilteredData(this.state.filters.searchText);
      }
    });
  };

  updateSearchText = (value, callback=null)=> {
    this.setState({
      ...this.state,
      filters: {
        ...this.state.filters,
        searchText: value
      }
    });
  };

  /**
   * Method is called when status filter is updated
   */
  updateStatusFilter = value => {
    let newArr = [...this.state.filters.status];
    let index = newArr.indexOf(value);
    if (index > -1) {
      newArr.splice(index, 1);
    } else {
      if (newArr.includes("all")) {
        newArr = [];
        STATUS_LIST.forEach(status => {
          if (value !== status.name) {
            newArr.push(status.name);
          }
        });
      } else {
        if (value === "all") {
          newArr = ["all"];
        } else {
          newArr.push(value);
        }
      }
    }

    if (newArr.length === STATUS_LIST.length) {
      newArr = ["all"];
    }

    this.setState({
      ...this.state,
      filters: {
        ...this.state.filters,
        status: newArr
      }
    });
  };

  /**
   * Method is called when buildings filter is updated
   */
  updateBuildingFilter = value => {
    //duplicate the state array
    let newArr = [...this.state.filters.buildings];
    //check to see if the value already exists in the array
    let index = newArr.indexOf(value);
    //if it exists
    if (index > -1) {
      //remove it from the newArr
      newArr.splice(index, 1);
    } else {
      //if the array includes "all"
      if (newArr.includes("all")) {
        newArr = [];
        //deselect only the one item that has been clicked
        this.state.buildings.forEach(bldg => {
          if (value !== bldg.id.toString()) {
            newArr.push(bldg.id.toString());
          }
        });
      } else {
        // else just add it to the array
        if (value === "all") {
          newArr = ["all"];
        } else {
          newArr.push(value);
        }
      }
    }

    //if all the items are selected
    if (newArr.length === this.state.buildings.length) {
      newArr = ["all"];
    }

    this.setState({
      ...this.state,
      filters: {
        ...this.state.filters,
        buildings: newArr
      }
    });
  };

  /**
   * Method is called when role fitler is updated
   */
  updateRoleFilter = value => {
    let newArr = [...this.state.filters.roles];
    let index = newArr.indexOf(value);
    if (index > -1) {
      newArr.splice(index, 1);
    } else {
      if (newArr.includes("all")) {
        newArr = [];
        this.state.roles.forEach(role => {
          if (value !== role.id.toString()) {
            newArr.push(role.id.toString());
          }
        });
      } else {
        if (value === "all") {
          newArr = ["all"];
        } else {
          newArr.push(value);
        }
      }
    }

    if (newArr.length === this.state.roles.length) {
      newArr = ["all"];
    }

    this.setState({
      ...this.state,
      filters: {
        ...this.state.filters,
        roles: newArr
      }
    });
  };

  /**
   * This method clears all the filters to default
   */
  clearFilters = () => {
    var all = ["all"];
    this.setState({
      ...this.state,
      filters: {
        ...this.state.filters,
        roles: [...all],
        buildings: [...all],
        status: [...all],
        searchText: ""
      }
    },()=>{
      this.getFilteredData();
    });
  };

  toggleTab = (tab) => {
    let tabs = {...this.state.tabs};
    switch(tab) {
      case LABEL.BLDG:
        tabs.building.open = !tabs.building.open;
        break;
      case LABEL.ROLE:
        tabs.role.open = !tabs.role.open;
        break;
      case LABEL.STATUS:
        tabs.status.open = !tabs.status.open;
      break;
    }
    this.setState({
      ...this.state,
      tabs: tabs
    });
  }

  isAllMessageDisabled = () => {

    let tabs = this.state.tabs;
    if(tabs.building.open || tabs.role.open || tabs.status.open) {
      return true;
    }
    return false;
  }
  

  /**
   * Renders the buttons to change the view
   * Filters or Correspondence
   */
  renderView = () => {
    let selectedClass = "allmessage__viewBtn--selected";

    let filterClass = "allmessage__viewBtn";
    let cspdClass = "allmessage__viewBtn";

    if (this.state.view === VIEW.CSPD) {
      cspdClass = cspdClass + " " + selectedClass;
    } else if (this.state.view === VIEW.FLTR) {
      filterClass = filterClass + " " + selectedClass;
    }

    return (
      <div className="allmessage__view">
        <span className="allmessage__viewBtnContainer">
          <span
            className={filterClass}
            onClick={() => this.updateView(VIEW.FLTR)}
          >
            {VIEW.FLTR}
          </span>
          <span
            className={cspdClass}
            onClick={() => this.updateView(VIEW.CSPD)}
          >
            {VIEW.CSPD}
          </span>
        </span>
      </div>
    );
  };

  /**
   * This method renders the filters according to
   * the view that is selected
   */
  renderFilters = () => {
    if (this.state.view === VIEW.CSPD) {
      return (
        <React.Fragment>
          <SearchBar
            text={this.state.filters.searchText}
            updateText={this.updateSearchText}
            filterData={this.getCspdFilteredData}
            typeSearch={true}
            typeSearchLength={3}
          />
        </React.Fragment>
      );
    } else if (this.state.view === VIEW.FLTR) {
      return (
        <React.Fragment>
          <MessageFilterSelector
            label={LABEL.BLDG}
            selectorContent={this.state.buildings}
            selected={this.state.filters.buildings}
            update={this.updateBuildingFilter}
            onClose={this.getFilteredData}
            toggleTab={this.toggleTab}
          />
          <MessageFilterSelector
            label={LABEL.ROLE}
            selectorContent={this.state.roles}
            selected={this.state.filters.roles}
            update={this.updateRoleFilter}
            onClose={this.getFilteredData}
            toggleTab={this.toggleTab}
          />

          <MessageFilterSelector
            label={LABEL.STATUS}
            selectorContent={STATUS_LIST}
            selected={this.state.filters.status}
            update={this.updateStatusFilter}
            onClose={this.getFilteredData}
            toggleTab={this.toggleTab}

          />
          <SearchBar
            text={this.state.filters.searchText}
            updateText={this.updateSearchText}
            filterData={this.getFilteredData}
            typeSearch={true}
            typeSearchLength={3}
          />
          <ActionBar
            isAllMessageDisabled={this.isAllMessageDisabled}
            clearFilters={this.clearFilters}
            audienceCount={this.state.messengerList.length}
            audience={this.state.messengerList}
            sms_allowed={this.state.sms_allowed}
          />
        </React.Fragment>
      );
    }
  };

  /**
   * This method renders the list of wardens
   * according to the view that is selected
   */
  renderMessengerList = () => {
    if (this.state.view === VIEW.CSPD) {
      return <MessengerList list={this.state.cspdList} />;
    } else {
      return <MessengerList list={this.state.messengerList} />;
    }
  };

  render() {
    return (
      <React.Fragment>
        <Loader loading={this.state.loader} />
        {this.renderView()}
        {this.renderFilters()}
        {this.renderMessengerList()}
      </React.Fragment>
    );
  };
}

export default AllMessage;
