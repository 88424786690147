import React, { Component } from "react";

class BuildingSelect extends Component {
  renderBuildings = () => {
    return this.props.no_event ? (
      <option className="floor__status--option">No Current Events</option>
    ) : (
      this.props.buildings.map(bldg => {
        return (
          <option
            key={bldg.id + " " + bldg.name + " selectoption"}
            value={bldg.id}
            className="floor__status--option"
          >
            {bldg.name}
          </option>
        );
      })
    );
  };

  handleBuildingChange = e => {
    this.props.handleBuildingSelectChange(parseInt(e.target.value, 10));
  };

  getSelectedValue = () => {
    return this.props.buildings
      .filter(bldg => {
        return bldg.selected === true;
      })
      .map(bldg => {
        return bldg.id.toString(10);
      })[0];
  };

  renderIfNotLoading = () => {
    if (!this.props.loading) {
      return (
        <select
          className="browser-default"
          onChange={this.handleBuildingChange}
          value={this.getSelectedValue()}
        >
          {this.renderBuildings()}
        </select>
      );
    } else {
      return <div className="skeleton skeleton__field br-10" />;
    }
  };

  render() {
    return (
      <div className="row">
        <div className="col s3">
          <div className="floor__status--label">Building:</div>
        </div>
        <div className="col s9">
          <div className="floor__status--select">
            {this.renderIfNotLoading()}
          </div>
        </div>
      </div>
    );
  }
}

export default BuildingSelect;
