import React, { Component } from "react";

class BuildingTable extends Component {
  renderTableHead = () => {
    let classNames = "row table__head";
    if (this.props.site_total) {
      classNames += " site__total";
    }
    return (
      <div className={classNames}>
        <div className="col s8 head__name">{this.props.building.name}</div>
        <div className="col s4 head__time">
          ({this.props.building.duration})
        </div>
      </div>
    );
  };

  renderTableRoles = () => {
    return this.props.building.roles.map(role => {
      if (
        role.on_duty !== 0 ||
        role.not_available !== 0 ||
        role.unknown !== 0 || 
        role.off_duty !== 0
      ) {
        return (
          <div className="row table__row" key={role.name + " tr"}>
            <div className="col s4">{role.name}</div>
            <div className="col s2 row__onduty">{role.on_duty}</div>
            <div className="col s2 row__unknown">{role.unknown}</div>
            <div className="col s2 row__notavailable">{role.not_available}</div>
            <div className="col s2 row__offduty">{role.off_duty}</div>
          </div>
        );
      } else {
        return null;
      }
    });
  };

  renderTableTotal = () => {
    return (
      <div className="row table__total">
        <div className="col s4">Total</div>
        <div className="col s2">{this.props.building.total.on_duty}</div>
        <div className="col s2">{this.props.building.total.unknown}</div>
        <div className="col s2">{this.props.building.total.not_available}</div>
        <div className="col s2">{this.props.building.total.off_duty}</div>
      </div>
    );
  };

  render() {
    return (
      <div className="table">
        {this.renderTableHead()}
        {this.renderTableRoles()}
        {this.renderTableTotal()}
      </div>
    );
  }
}

export default BuildingTable;
