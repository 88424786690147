import React from "react";
import { Link } from "react-router-dom";

import { route } from "../../Routes";

const ActionBar = props => {
  const checkDisabled = event => {
    if (props.audienceCount === 0 || props.isAllMessageDisabled()) {
      event.preventDefault();
      return true;
    }
    else
    {
        return false;
    }
  };

  let messageAllClass = "allmessage__actionbar--actionbtn";

  if(props.audienceCount === 0 || props.isAllMessageDisabled()){
    messageAllClass = "allmessage__actionbar--actionbtn actionbtn__disabled"
  }
  
  return (
    <div className="row allmessage__actionbar--container">
      <div className="col s12 allmessage__actionbar">
        <div className="col s6 allmessage__actionbar--action">
          <span className="allmessage__actionbar--text">
            Audience count : {props.audienceCount}
          </span>
        </div>
        <div className="col s3 allmessage__actionbar--action">
          <Link
            onClick={e => checkDisabled(e)}
            to={{
              pathname: route.message.group.link,
              state: {
                audience: props.audience,
                sms_allowed: props.sms_allowed
              }
            }}
            className={messageAllClass}
          >
            Message All
          </Link>
        </div>
        <div className="col s3 allmessage__actionbar--action">
          <button
            className="allmessage__actionbar--actionbtn"
            onClick={props.clearFilters}
          >
            Clear Filters
          </button>
        </div>
      </div>
    </div>
  );
};

export default ActionBar;
