import React, { Component } from "react";
import SideNavigation from "../../SideNavigation";
import Modal from "../Modal";
import Toast, { TOAST_TYPE } from "../Toast";
import { api_logout_url } from "../../../config";
import axios from "axios";
import ls from "local-storage";
import { withRouter } from "react-router";

import { route } from "../../Routes";

import "./scss/header.scss";

class Header extends Component {
  state = {
    nav: {
      show: false
    },
    modal: {
      show: false
    },
    toast: {
      show: false,
      message: ""
    }
  };

  displayTitle = () => {
    if (this.props.title !== undefined && this.props.title.length !== 0) {
      return this.props.title;
    }
    return "All Wardens - Evac";
  };

  toggleNavDisplay = (callback = null) => {
    this.forceUpdate();
    if (this.props.showNav !== undefined) {
      if (this.props.showNav === true) {
        this.props.close(callback);
      } else {
        this.props.open(callback);
      }
    } else {
      this.setState(
        {
          ...this.state,
          nav: {
            show: !this.state.nav.show
          }
        },
        () => {
          if (callback && typeof callback === "function") {
            callback();
          }
        }
      );
    }
  };

  closeNav = () => {
    this.setState({
      ...this.state,
      nav: {
        show: false
      }
    });
  };

  openNav = () => {
    this.setState({
      ...this.state,
      nav: {
        show: true
      }
    });
  };

  /**
   * This method logs user out from the application
   * by deleting ther access token from the server
   * and also deleting local storage data
   */
  handleLogout = () => {
    axios
      .delete(api_logout_url)
      .then(resp => {
        if (resp.data.status === "logout_success") {
          ls.clear();
          this.setState({
            ...this.state,
            nav: {
              show: false
            },
            modal: {
              show: false
            }
          });
          this.props.history.push(route.login.link);
        } else if (resp.data.status === "cannot_logout") {
          this.setState({
            ...this.state,
            nav: {
              show: false
            },
            modal: {
              show: false
            },
            toast: {
              show: true,
              message: resp.data.message
            }
          });
        }
      })
      .catch(err => {
        console.log(err);
      });
  };

  openModal = () => {
    this.setState({
      ...this.state,
      modal: {
        show: true
      }
    });
  };

  closeModal = () => {
    this.setState({
      ...this.state,
      modal: {
        show: false
      }
    });
  };

  closeToast = () => {
    this.setState({
      ...this.state,
      toast: {
        show: false,
        message: ""
      }
    });
  };

  componentDidMount() {
    if (this.props.showNav !== undefined) {
      this.setState({
        ...this.state,
        nav: {
          show: this.props.showNav
        }
      });
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.showNav !== prevProps.showNav) {
      this.setState({
        ...this.state,
        nav: {
          show: this.props.showNav
        }
      });
    }
  }

  render() {
    return (
      <React.Fragment>
        <Modal
          id="confirmationPopup"
          show={this.state.modal.show}
          close={this.closeModal}
          baseClasses="modal-confirmation"
          contentClasses="modal-content-confirmation"
        >
          <div className="confirm confirm-bg">
            <p className="center-align">Go Off Duty?</p>
          </div>
          <div className="container">
            <div className="row">
              <div className="col s12 center-align">
                You will be reported as Off Duty and signed out of the current
                event.
                <br /> <b>You can sign in again if necessary.</b>
              </div>
            </div>
            <div className="row actionGroup">
              <div className="col s6 center-align">
                <button
                  className="btn-small darken-2 red--bg"
                  onClick={this.handleLogout}
                >
                  Logout
                </button>
              </div>
              <div className="col s6 center-align">
                <button
                  className="btn-small green--bg lighten-1"
                  onClick={this.closeModal}
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </Modal>

        <Toast
          key="header Toast"
          type={TOAST_TYPE.DANGER}
          show={this.state.toast.show}
          close={this.closeToast}
        >
          {this.state.toast.message}
        </Toast>

        <div className="report__header black">
          <SideNavigation
            title={this.props.title}
            showBackBtn={
              this.props.showBackBtn !== undefined
                ? this.props.showBackBtn
                : false
            }
            show={
              this.props.showNav !== undefined
                ? this.props.showNav
                : this.state.nav.show
            }
            toggleNav={this.toggleNavDisplay}
            showBurgerMenu={
              this.props.showBurgerMenu !== undefined &&
              this.props.showBurgerMenu !== null
                ? this.props.showBurgerMenu
                : true
            }
            openModal={this.openModal}
            closeModal={this.closeModal}
            handleLogout={this.handleLogout}
            draggable={this.props.menuDrag}
            close={
              this.props.close !== undefined ? this.props.close : this.closeNav
            }
            open={
              this.props.open !== undefined ? this.props.open : this.openNav
            }
            updateNav={this.props.updateNav}
          />
          <div className="left report__title white-text valign-wrapper">
            <h6>{this.displayTitle()}</h6>
          </div>
          <div className="right report__logo">
            <div className="brand-logo1x" />
          </div>
        </div>
        {/* {this.props.showBurgerMenu !== undefined &&
        !this.props.showBurgerMenu ? (
          <div className="optional__nav">
            <div className="optional__nav--backbtn left">
              <button className="back" onClick={this.props.history.goBack}>
                <i class="material-icons">keyboard_arrow_left</i>
              </button>
            </div>
            <div className="optional__nav--otherbuttons right">
              <button className="logout" onClick={this.handleLogout}>
                Logout
              </button>
            </div>
          </div>
        ) : null} */}
      </React.Fragment>
    );
  }
}

export default withRouter(Header);
