import React, { Component } from "react";

import { Link, withRouter } from "react-router-dom";
import { route } from "../Routes";
import ls from "local-storage";

import "./scss/sidenav.scss";

class SideNavigation extends Component {
  sideNavElem = null;
  sideNav = null;

  state = {
    loaded: false,
    access: null
  };

  componentDidMount() {
    this.sideNavElem = this.refs.SideNavComponent;
    this.sideNav = window.M.Sidenav.init(this.sideNavElem, {
      draggable: this.props.draggable,
      onCloseStart: () => {
        this.props.close();
      }
    });
    this.loadSideNav();

    this.getModuleAccess();
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.access === null) {
      this.getModuleAccess();
    }
    this.loadSideNav();
  }

  componentWillUnmount() {
    this.sideNav.destroy();
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (this.props.updateNav !== nextProps.updateNav) {
      this.getModuleAccess();
      return nextState;
    }
    return nextState;
  }

  getModuleAccess = () => {
    if (ls.get("module_access") !== null) {
      this.setState({
        loaded: true,
        access: ls.get("module_access")
      });
    }
  };

  loadSideNav = () => {
    // var sideNavElem = this.refs.SideNavComponent;
    // var sideNav = window.M.Sidenav.init(sideNavElem, {
    //   draggable: this.props.draggable,
    //   onCloseStart: () => {
    //     this.props.close();
    //   }
    // });

    // if (this.props.show) {
    //   sideNav.open();
    // } else {
    //   sideNav.close();
    // }

    if (this.props.show) {
      this.sideNav.open();
    } else {
      this.sideNav.close();
    }
  };

  handleSignOut = () => {
    this.props.toggleNav(this.props.openModal);
  };

  renderActionButton = () => {
    if (this.props.title === route.reportin.title) {
      if (this.props.showBurgerMenu) {
        return (
          <button
            data-target="slide-out"
            className="sidenav-trigger left"
            onClick={this.props.toggleNav}
          >
            <i className="material-icons">menu</i>
          </button>
        );
      }
    } else {
      if (this.props.showBurgerMenu) {
        return (
          <button
            data-target="slide-out"
            className="sidenav-trigger left"
            onClick={this.props.toggleNav}
          >
            <i className="material-icons">menu</i>
          </button>
        );
      } else if (this.props.showBackBtn && !this.props.showBurgerMenu) {
        return (
          <button
            className="sidenav-trigger left"
            onClick={this.props.history.goBack}
          >
            <i className="material-icons">keyboard_arrow_left</i>
          </button>
        );
      } else {
        return null;
      }
    }
  };

  render() {
    return (
      <React.Fragment>
        <ul id="slide-out" className="sidenav" ref="SideNavComponent">
          <li className="sidenav__title">warden menu</li>

          <li className="sidenav__subtitle">evacuee management</li>
          {this.state.loaded && this.state.access.Module_Floor_Evacuation ? (
            <li className="sidenav__link">
              <Link onClick={this.props.close} to={route.floor.evacuation.link}>
                <i className="sidenav__icon icon-floorevac" />
                floor evacuation
              </Link>
            </li>
          ) : null}

          {this.state.loaded && this.state.access.Module_Evacuee_Safety ? (
            <li className="sidenav__link">
              <Link onClick={this.props.close} to={route.evacuee.safety.link}>
                <i className="sidenav__icon icon-evacsaftey" />
                evacuee saftey
              </Link>
            </li>
          ) : null}

          <li className="sidenav__subtitle">evacuation status</li>
          {this.state.loaded &&
          this.state.access.Module_Floor_Evacuation_Status ? (
            <li className="sidenav__link">
              <Link onClick={this.props.close} to={route.floor.evacstatus.link}>
                <i className="sidenav__icon icon-floorevac" />
                floor evacuation status
              </Link>
            </li>
          ) : null}

          {this.state.loaded &&
          this.state.access.Module_Building_Evacuation_Status ? (
            <li className="sidenav__link">
              <Link onClick={this.props.close} to={route.building.status.link}>
                <i className="sidenav__icon icon-bldgevacstatus" />
                building evacuation status
              </Link>
            </li>
          ) : null}

          {this.state.loaded && this.state.access.Module_Wardens_On_Duty ? (
            <li className="sidenav__link">
              <Link onClick={this.props.close} to={route.warden.onduty.link}>
                <i className="sidenav__icon icon-wardensonduty" />
                {route.warden.onduty.title}
              </Link>
            </li>
          ) : null}

          {this.state.loaded && this.state.access.Module_Floor_Warden_Status ? (
            <li className="sidenav__link">
              <Link
                onClick={this.props.close}
                to={route.floor.warden.status.link}
              >
                <i className="sidenav__icon icon-floorwardenstatus" />
                {route.floor.warden.status.title}
              </Link>
            </li>
          ) : null}

          <li className="sidenav__subtitle">warden activities</li>
          {/* <li className="sidenav__link">
            <a href="/cw/broadcast">
              <i className="sidenav__icon icon-cwbroadcast" />
              warden messaging
            </a>
          </li> */}
          {this.state.loaded && this.state.access.Module_Warden_Messaging ? (
            <li className="sidenav__link">
              <Link to={route.message.all.link} onClick={this.props.close}>
                <i className="sidenav__icon icon-mymessages" />
                {route.message.all.title}
              </Link>
            </li>
          ) : null}
          {this.state.loaded && this.state.access.Module_Event_Management ? (
            <li className="sidenav__link">
              <Link onClick={this.props.close} to={route.event.management.link}>
                <i className="sidenav__icon icon-eventmgmt" />
                {route.event.management.title}
              </Link>
            </li>
          ) : null}

          <li className="sidenav__link">
            <Link onClick={this.props.close} to={route.me.role.link}>
              <i className="sidenav__icon icon-myrole" />
              {route.me.role.title}
            </Link>
          </li>

          <li className="sidenav__sep" />

          <li className="sidenav__link">
            <button onClick={this.handleSignOut}>
              <i className="sidenav__icon icon-signoff" />
              <span className="sidenav__link--text">sign off</span>
            </button>
          </li>
        </ul>
        {this.renderActionButton()}
      </React.Fragment>
    );
  }
}

export default withRouter(SideNavigation);
