import React, { Component } from "react";
import PeepPopUp from "./PeepPopUp";

import defaultImage from "../../../../images/peep/default.jpg";

class PeepListItem extends Component {
  state = {
    showPeepPopUp: false
  };

  openPeepPopUp = () => {
    this.setState({
      showPeepPopUp: true
    });
  };

  closePeepPopUp = () => {
    this.setState({
      showPeepPopUp: false
    });
  };

  renderImage = () => {
    let image = defaultImage;
    if (this.props.info.image !== null) {
      image = this.props.info.image;
    }
    return (
      <div className="peep__image">
        <img alt={this.props.info.name + "'s photo"} src={image} />
      </div>
    );
  };

  render() {
    return (
      <div className="row peep__list--item">
        {/* <div className="col s3">{this.renderImage()}</div> */}
        <div className="col s12">
          <div className="peep__info">
            <div className="col s12 truncate">
              <span className="info__name">{this.props.info.name}</span>
            </div>
            <div className="col s5">
              <span className="info__phone">{this.props.info.phone}</span>
            </div>
            <div className="col s7">
              <span className="info__location">{this.props.info.location}</span>
            </div>
            <div className="col s5">
              <div className="info__badge" onClick={this.openPeepPopUp}>
                <span className="peep__badge">PEEP</span>
              </div>
            </div>
            <div className="col s7">
              <select
                className="browser-default"
                value={this.props.info.status}
                onChange={e => {
                  this.props.handlePeepStatusChange(
                    this.props.info.id,
                    e.target.value
                  );
                }}
              >
                {this.props.pwdmi_statuses.map(status => {
                  return (
                    <option key={this.props.info.id + status}>{status}</option>
                  );
                })}
              </select>
            </div>
          </div>
        </div>
        <PeepPopUp
          show={this.state.showPeepPopUp}
          name={this.props.info.name}
          details={this.props.info.details}
          close={this.closePeepPopUp}
        />
      </div>
    );
  }
}

export default PeepListItem;
