import React from "react";

const MessageTemplateItem = props => {
  return (
    <div
      className="col s12 messagebox__templateItem"
      onClick={() => props.insertTemplate(props.template.id)}
    >
      {props.template.text}
    </div>
  );
};

export default MessageTemplateItem;
