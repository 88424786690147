import React, { Component } from "react";

import PersonListItem from "./PersonListItem";

import { api_floor_evacuation_evacuee_search } from "../../../../config";

import refusal_black from "../images/refusal_black.png";

import axios from "axios";

class PersonList extends Component {
  state = {
    show: true,
    search: "",
    searchPersons: []
  };

  toggleCollapse = () => {
    this.setState({
      ...this.state,
      show: !this.state.show
    });
  };

  renderCollapseArrow = () => {
    let arrow = "keyboard_arrow_down";
    if (!this.state.show) {
      arrow = "keyboard_arrow_up";
    }
    return arrow;
  };

  renderReportedCount = () => {
    return this.props.refusal_to_evacuate;
  };

  renderListHeader = () => {
    return (
      <div
        className="list__header refusal__header"
        onClick={this.toggleCollapse}
      >
        <div className="container">
          <div className="row">
            <div className="col s2">
              {/* <span className="header__icon">
                <i className="material-icons">directions_run</i>
              </span> */}
              <span className="list__header--refusal">
                <img src={refusal_black} alt="refusal img"/>
              </span>
            </div>
            <div className="col s7">
              <span className="header__title">Refusal To Evacuate</span>
            </div>
            <div className="col s2">
              <span className="header__count">
                {this.renderReportedCount()}
              </span>
            </div>
            <div className="col s1">
              <span className="header__collapsible">
                <i className="material-icons">{this.renderCollapseArrow()}</i>
              </span>
            </div>
          </div>
        </div>
      </div>
    );
  };

  handleSearchChange = e => {
    this.setState(
      {
        ...this.state,
        search: e.target.value
      },
      () => {
        if (this.state.search.length >= 3) {
          this.searchEvacuees();
        }
      }
    );
  };

  componentDidUpdate(prevProps, prevState) {
    if (
      this.state.search !== prevState.search &&
      this.state.search.length === 0
    ) {
      this.props.loadPaginatedEvacuees();
    }
  }

  searchEvacuees = () => {
    let searchUrl =
      api_floor_evacuation_evacuee_search + "/" + this.state.search;

    axios
      .get(searchUrl)
      .then(resp => {
        this.props.setEvacueesList(resp.data);
      })
      .catch(err => {
        console.log(err);
      });
  };

  renderEvacueeSearchBar = () => {
    return (
      <div className="container person__search">
        <input
          type="text"
          className="browser-default input__search"
          placeholder="search"
          value={this.state.search}
          onChange={this.handleSearchChange}
        />
      </div>
    );
  };

  renderPersonList = () => {
    if (this.props.persons.length > 0) {
      return (
        <div className="container person__list">
          {this.props.persons.map(person => {
            return (
              <PersonListItem
                key={person.id + person.name + "person"}
                info={person}
                handleReportedChange={this.props.handleReportedChange}
              />
            );
          })}
        </div>
      );
    } else {
      return (
        <div className="container">
          <p className="center-text grey-text text-lighten-1">
            No Evacuees Found..
          </p>
        </div>
      );
    }
  };

  renderSearchAndList = () => {
    let className = "show";
    if (!this.state.show) {
      className = "hide";
    }
    return (
      <div className={className}>
        {this.renderEvacueeSearchBar()}
        {this.renderPersonList()}
      </div>
    );
  };

  renderSkeleton = () => {
    return (
      <div className="container mt-20">
        <div className="skeleton skeleton__table" />
      </div>
    );
  };

  render() {
    if (!this.props.loading) {
      return (
        <React.Fragment>
          {this.renderListHeader()}
          {this.renderSearchAndList()}
        </React.Fragment>
      );
    } else {
      return <React.Fragment>{this.renderSkeleton()}</React.Fragment>;
    }
  }
}

export default PersonList;
