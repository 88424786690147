import React, { Component } from "react";
import { Link } from "react-router-dom";
import { route } from "../../../Routes";

import swipedetect from "../../../Utilities/SwipeDetect";

import msg_unread_img from "../../Template/images/msg_unread.png";

class MessengerListItem extends Component {
  itemRef = React.createRef();
  leftItemRef = React.createRef();

  swipeleftClass = "messengerlistitem__info--swipedleft";
  swipedleftClass = "messengerlistitem__swipedleft";

  swipehintleftClass = "swipehint--left";
  swipelefthintcontentClass = "swipehintleft--content";

  componentDidMount() {
    if (this.props.disableSwipe === undefined) {
      !this.props.disableSwipe && swipedetect(this.itemRef.current, swipedir => {
        var target = this.itemRef.current;
        var swipeLeftTarget = this.leftItemRef.current;

        if (swipedir === "left") {
          target.classList.add(this.swipeleftClass);
          swipeLeftTarget.classList.add(this.swipedleftClass);
        } else if (swipedir === "right") {
          target.classList.remove(this.swipeleftClass);
          swipeLeftTarget.classList.remove(this.swipedleftClass);
        }
      });
    }
  }

  renderArea = () => {
    return (
      this.props.info.areas !== undefined &&
      this.props.info.areas.length > 0 &&
      this.props.info.areas.map(location => {
        return (
          <div
            className="col s12"
            key={this.props.info.id + location.id + this.props.info.role}
          >
            {location.name}
          </div>
        );
      })
    );
  };

  renderStatus = () => {
    let className = "green--bg";
    let status = this.props.info.status.toLowerCase();

    let unread = this.props.info.unread;

    if (status === "off duty") {
      className = "red--bg";
    } else if (status === "not available" || status === "unknown") {
      className = "yellow--bg";
    }

    return (
      <div className="col s5 messengerlistitem__status">
        <div className={"messengerlistitem__dutystatus " + className}>
          {status}
        </div>
        {unread ? (
          <div className="messengerlistitem__messagestatus">
            <img src={msg_unread_img} alt="unread messages" />
          </div>
        ) : null}
      </div>
    );
  };

  renderSwipeLeft = () => {
    return (
      <div className="row messengerlistitem__swipeleft" ref={this.leftItemRef}>
        <div className="col s6">
          <a href={"tel:" + this.props.info.phone}>
            <button className="messengerlistitem__swipeleft--call">
              <i className="material-icons">call</i>
            </button>
          </a>
        </div>

        <div className="col s6">
          <Link
            to={
              route.message.warden.link +
              "/" +
              this.props.info.id +
              "?from=" +
              route.message.all.link
            }
          >
            <button className="messengerlistitem__swipeleft--message">
              <i className="material-icons">message</i>
            </button>
          </Link>
        </div>
      </div>
    );
  };

  render() {
    return (
      <div className="messengerlistitem__container">
        <div
          className="messengerlistitem__info"
          id={this.props.info.id}
          ref={this.itemRef}
        >
          <div className="col s12 messengerlistitem__name">
            {this.props.info.name}
          </div>
          <div className="row messengerlistitem__bottom">
            <div className="col s7">
              <div className="col s12 messengerlistitem__role">
                {this.props.info.role}
              </div>
              <div className="col s12 mesengerlistitem__area">
                {this.renderArea()}
              </div>
            </div>
            {this.renderStatus()}
          </div>
        </div>
        {this.renderSwipeLeft()}
      </div>
    );
  }
}

export default MessengerListItem;
