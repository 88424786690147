import React, { Component } from "react";

import MessengerListItem from "./MessengerListItem";

import "./scss/messengerlist.scss";

class MessengerList extends Component {
  renderMessengerListItems = () => {
    return (
      this.props.list.length > 0 ?
      this.props.list.map(warden => {
        return <MessengerListItem info={warden} key={warden.id+" WardenListItem"} disableSwipe={this.props.disableSwipe}/>;
      }) : <div className="container row"><div className="col s12 messengerlist__notfound">No Wardens Found.</div></div>
    );
  };

  render() {
    return (
      <div className="messengerlist__container">
        {this.renderMessengerListItems()}
      </div>
    );
  }
}

export default MessengerList;
