import React, { Component } from "react";

import { STATUS } from "./index";

class DisplayBar extends Component {
  render() {
    let active = this.props.showDisplay;

    let defaultClass = "col s3";
    let activeClass = defaultClass + " evacsafety__displaybar--active";

    let totalClass = active === STATUS.TOTAL ? activeClass : defaultClass;
    let safeClass = active === STATUS.SAFE ? activeClass : defaultClass;
    let unknownClass = active === STATUS.UNKNOWN ? activeClass : defaultClass;
    let dangerClass = active === STATUS.DANGER ? activeClass : defaultClass;

    return (
      <div className="row evacsafety__displaybar">
        <div
          className={safeClass}
          onClick={() => {
            this.props.toggleDisplay(STATUS.SAFE);
          }}
        >
          <div className="evacsafety__displaybar--top">{STATUS.SAFE}</div>
          <div className="evacsafety__displaybar--bottom">
            {this.props.counts.safe}
          </div>
        </div>
        <div
          className={unknownClass}
          onClick={() => {
            this.props.toggleDisplay(STATUS.UNKNOWN);
          }}
        >
          <div className="evacsafety__displaybar--top">{STATUS.UNKNOWN}</div>
          <div className="evacsafety__displaybar--bottom">
            {this.props.counts.unknown}
          </div>
        </div>
        <div
          className={dangerClass}
          onClick={() => {
            this.props.toggleDisplay(STATUS.DANGER);
          }}
        >
          <div className="evacsafety__displaybar--top">{STATUS.DANGER}</div>
          <div className="evacsafety__displaybar--bottom">
            {this.props.counts.danger}
          </div>
        </div>
        <div
          className={totalClass}
          onClick={() => {
            this.props.toggleDisplay(STATUS.TOTAL);
          }}
        >
          <div className="evacsafety__displaybar--top">{STATUS.TOTAL}</div>
          <div className="evacsafety__displaybar--bottom">
            {this.props.counts.total}
          </div>
        </div>
      </div>
    );
  }
}

export default DisplayBar;
