import React, { Component } from "react";

import BuildingTableList from "./BuildingTableList";
import EvacueeStatusSummary from "../../Utilities/EvacueeStatusSummary";

import axios from "axios";
import { api_warden_data_url } from "../../../config";

import "./scss/warden-onduty.scss";

class WardensOnDuty extends Component {
  state = {
    loading: false,
    no_event: true,
    site: "Thomas St",
    buildings: [
      {
        id: 1,
        name: "Building A",
        duration: "1hr:32m",
        roles: [
          {
            id: 1,
            name: "Chief Warden",
            on_duty: 2,
            unknown: 0,
            not_available: 0,
            off_duty: 0
          },
          {
            id: 2,
            name: "Floor Warden",
            on_duty: 24,
            unknown: 6,
            off_duty: 0,
            not_available: 5
          }
        ],
        total: {
          on_duty: 26,
          off_duty: 0,
          unknown: 6,
          not_available: 5
        }
      },
      {
        id: 2,
        name: "Building B",
        duration: "0hr:55m",
        roles: [
          {
            id: 1,
            name: "Chief Warden",
            on_duty: 2,
            unknown: 0,
            off_duty: 0,
            not_available: 0
          },
          {
            id: 2,
            name: "Floor Warden",
            on_duty: 24,
            unknown: 6,
            off_duty: 0,
            not_available: 5
          }
        ],
        total: {
          on_duty: 26,
          unknown: 6,
          off_duty: 0,
          not_available: 5
        }
      }
    ],
    site_total: {
      name: "Site Total",
      duration: "1hr:32:m",
      roles: [
        {
          id: 1,
          name: "Chief Warden",
          on_duty: 2,
          unknown: 0,
          off_duty: 0,
          not_available: 0
        },
        {
          id: 2,
          name: "Floor Warden",
          on_duty: 24,
          unknown: 6,
          off_duty: 0,
          not_available: 5
        }
      ],
      total: {
        on_duty: 26,
        unknown: 6,
        off_duty: 0,
        not_available: 5
      }
    }
  };

  loading = loadState => {
    this.setState({
      ...this.state,
      loading: loadState
    });
  };

  componentDidMount() {
    this.getWardensOnDutyData();
  }

  getWardensOnDutyData = () => {
    this.loading(true);
    axios
      .get(api_warden_data_url)
      .then(resp => {
        this.setState(
          {
            ...this.state,
            buildings: resp.data.buildings,
            site: resp.data.site,
            site_total: resp.data.site_total,
            no_event: resp.data === "" ? true : false
          },
          () => {
            this.loading(false);
          }
        );
      })
      .finally(resp => {
        this.loading(false);
      });
  };

  renderSiteName = () => {
    return (
      <div className="row mt-10">
        {this.state.loading ? (
          <div className="skeleton skeleton__field br-10" />
        ) : (
          <React.Fragment>
            <div className="col s2 site__label">
              <span className="label">Site:</span>
            </div>
            <div className="col s10 site__textbox">
              {this.state.no_event ? "No Current Events" : this.state.site}
            </div>
          </React.Fragment>
        )}
      </div>
    );
  };

  renderColumnIndicators = () => {
    return (
      <div className="row column__indicators">
        {this.state.loading ? (
          <div className="skeleton skeleton__field mt-30" />
        ) : (
          <React.Fragment>
            <div className="col s6" />
            <div className="col s2">
              <div className="indicator__onduty">On Duty</div>
            </div>
            <div className="col s2">
              <div className="indicator__unknown">Unknown</div>
            </div>
            <div className="col s2">
              <div className="indicator__notavailable">Not Avail</div>
            </div>
          </React.Fragment>
        )}
      </div>
    );
  };

  renderStatusSummary = () => {
    let statsSummary = {
      safe: this.state.site_total.total.on_duty,
      unknown: this.state.site_total.total.unknown,
      danger: this.state.site_total.total.not_available,
      total: this.state.site_total.total.off_duty
    };
    return this.state.loading ? (
      <div className="skeleton skeleton__field mt-30" />
    ) : (
      <EvacueeStatusSummary
        showBar={true}
        hasTotal={false}
        summary={statsSummary}
        col1="On Duty"
        col2="Unknown"
        col3="Not Avail"
        col4="Off Duty"
      />
    );
  };

  render() {
    return (
      <React.Fragment>
        {/* <Loader loading={this.state.loading} /> */}
        {this.renderSiteName()}
        {this.renderStatusSummary()}

        {/* {!this.state.no_event ? this.renderColumnIndicators() : null} */}
        <BuildingTableList
          loading={this.state.loading}
          buildings={this.state.buildings}
          site_total={this.state.site_total}
          no_event={this.state.no_event}
        />
      </React.Fragment>
    );
  }
}

export default WardensOnDuty;
