import React, { Component } from "react";
import { withRouter } from "react-router";

import RecipientInfo, { INFO_TYPE } from "../Template/RecipientInfo";
import MessageBubble, { BUBBLE } from "../Template/MessageBubble";
import MessageBox, { TEMP } from "../Template/MessageBox";
import Toast, { TOAST_TYPE } from "../../Utilities/Toast";

import axios from "axios";

import {
  api_evacuee_message_data,
  api_evacuee_message_poll,
  api_evacuee_message_send,
  CancelTokenSource
} from "../../../config";

import "./scss/main.scss";

const POLLING_DELAY = 10000; //10s
class EvacueeMessage extends Component {
  messagesEnd = React.createRef();

  state = {
    loader: false,
    toEvacuee: 0,
    fromUrl: "",
    sms_allowed: true,
    toast: {
      show: false,
      type: TOAST_TYPE.DANGER,
      text: ""
    },
    recipient: {
      id: "1",
      name: "Andrew Smith",
      phone: "045231212",
      location: "Floor - 02",
      status: "DANGER"
    },
    messages: [
      {
        messageId: "1",
        senderId: "10",
        name: "Sam Jones",
        role: "Chief Warden",
        timestamp: "12:34pm",
        read: 0,
        text: `Lorem Ipsum is simply dummy text of the printing and typesetting industry.
         Lorem Ipsum has been the industry's standard dummy text ever since the 1500s`
      },
      {
        messageId: "2",
        senderId: "1",
        name: "Andrew Smith",
        role: "Evacuee",
        timestamp: "12:45pm",
        text: `Lorem Ipsum is simply dummy text of the printing and typesetting industry.
         Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley
         of type and scrambled it to make a type specimen book. It has
         survived not only five centuries`,
        read: 1
      },
      {
        messageId: "3",
        senderId: "1",
        name: "Andrew Smith",
        role: "Evacuee",
        timestamp: "12:50pm",
        read: 1,
        text: `Lorem Ipsum is simply dummy text of the printing and typesetting industry.
         Lorem Ipsum has been the industry's standard dummy text ever since the 1500s`
      },
      {
        messageId: "4",
        senderId: "10",
        name: "Sam Jones",
        role: "Chief Warden",
        timestamp: "12:55pm",
        read: 0,
        text: `Lorem Ipsum is simply dummy text of the printing and typesetting industry.
         Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley
         of type and scrambled it to make a type specimen book. It has
         survived not only five centuries`
      },
      {
        messageId: "5",
        senderId: "1",
        name: "Andrew Smith",
        role: "Evacuee",
        timestamp: "12:50pm",
        read: 0,
        text: `Lorem Ipsum is simply dummy text of the printing and typesetting industry.
         Lorem Ipsum has been the industry's standard dummy text ever since the 1500s`
      }
    ]
  };

  componentDidMount() {
    let params = new URLSearchParams(this.props.location.search);

    this.setState(
      {
        ...this.state,
        toEvacuee: this.props.match.params.evacueeId,
        fromUrl: params.get("from")
      },
      () => {
        this.getInitialState();
      }
    );

    this.timer = setInterval(() => {
      this.pollForMessages();
    }, POLLING_DELAY);
  }

  componentDidUpdate(prevState, prevProps) {
    this.scrollToBottom();
    window.addEventListener("resize", this.scrollToBottom);
  }

  componentWillUnmount() {
    clearInterval(this.timer);
  }

  getInitialState = () => {
    this.loader(true);
    axios
      .get(api_evacuee_message_data, {
        cancelToken: CancelTokenSource.token,
        params: {
          toEvacuee: this.state.toEvacuee
        }
      })
      .then(resp => {
        this.setState({
          ...this.state,
          ...resp.data,
          loader: false
        });
        this.scrollToBottom();
      })
      .catch(err => {
        if (axios.isCancel(err)) {
          console.log("Request Canceled", err.message);
        } else {
          this.loader(false);
          this.setToast(
            TOAST_TYPE.DANGER,
            true,
            "Error loading data. Please refresh."
          );
        }
      });
  };

  /**
   * This method checks for new messages
   */
  pollForMessages = () => {
    axios
      .get(api_evacuee_message_poll, {
        cancelToken: CancelTokenSource.token,
        params: {
          toEvacuee: this.state.toEvacuee
        }
      })
      .then(resp => {
        let toScroll = false;
        if (resp.data.messages.length !== this.state.messages.length) {
          toScroll = true;
        }
        this.setState(
          {
            ...this.state,
            ...resp.data
          },
          () => {
            if (toScroll) {
              this.scrollToBottom();
            }
          }
        );
      })
      .catch(err => {
        if (axios.isCancel(err)) {
          console.log("Request Canceled", err.message);
        } else {
          this.setToast(
            TOAST_TYPE.DANGER,
            true,
            "Couldnt Poll for new data. Please refresh."
          );
        }
      });
  };

  scrollToBottom = () => {
    if (this.messageEnd !== null) {
      this.messagesEnd.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  loader = loader => {
    this.setState({
      ...this.state,
      loader
    });
  };

  setToast = (type, show = true, text) => {
    this.setState({
      ...this.state,
      toast: {
        type,
        show,
        text
      }
    });
  };

  closeToast = () => {
    this.setState({
      ...this.state,
      toast: {
        show: false
      }
    });
  };

  send = message => {
    let data = {
      message: message,
      receiverId: this.state.recipient.id
    };

    axios
      .post(api_evacuee_message_send, data, {
        cancelToken: CancelTokenSource.token
      })
      .then(resp => {
        if (resp.data.status === "message_sent") {
          this.setState({
            ...this.state,
            messages: [...resp.data.newMessages]
          });
          this.scrollToBottom();
        } else {
          this.setToast(TOAST_TYPE.DANGER, true, resp.data.message);
        }
      })
      .catch(err => {
        if (axios.isCancel(err)) {
          console.log("Request Canceled", err.message);
        } else {
          this.setToast(
            TOAST_TYPE.DANGER,
            true,
            "Error while sending message. Please refresh."
          );
        }
      });
  };

  renderMessageHistory = () => {
    return !this.state.loader ? (
      this.state.messages.map(message => {
        let type =
          this.state.recipient.id === message.senderId &&
          message.role === "Evacuee"
            ? BUBBLE.FROM
            : BUBBLE.TO;
        return (
          <MessageBubble
            key={message.messageId}
            type={type}
            message={message}
            recipient={this.state.recipient}
          />
        );
      })
    ) : (
      <React.Fragment>
        <MessageBubble loading={this.state.loader} type={BUBBLE.TO} />
        <MessageBubble loading={this.state.loader} type={BUBBLE.FROM} />
        <MessageBubble loading={this.state.loader} type={BUBBLE.FROM} />
        <MessageBubble loading={this.state.loader} type={BUBBLE.TO} />
        <MessageBubble loading={this.state.loader} type={BUBBLE.FROM} />
        <MessageBubble loading={this.state.loader} type={BUBBLE.TO} />
        <MessageBubble loading={this.state.loader} type={BUBBLE.FROM} />
      </React.Fragment>
    );
  };

  renderNoMessagesError = () => {
    return !this.state.loader && this.state.messages.length === 0 ? (
      <div className="col s12 center-text message__history--nomessage">
        No Messages
      </div>
    ) : null;
  };

  renderCanSendError = () => {
    return !this.state.loader && !this.state.sms_allowed ? (
      <div className="center-text message__history--cansend">
        ALL WARDENS SMS messaging is not activated. Use your device's native SMS
        or phone to communicate with occupants.
      </div>
    ) : null;
  };

  canSend = () => {
    return this.state.sms_allowed ? true : false;
  };

  render() {
    return (
      <React.Fragment>
        <Toast
          show={this.state.toast.show}
          close={this.closeToast}
          delay={2000}
        >
          {this.state.toast.text}
        </Toast>
        <RecipientInfo
          loading={this.state.loader}
          recipient={this.state.recipient}
          back={this.state.fromUrl}
          type={INFO_TYPE.WARDEN}
        />
        <div className="row message__history">
          {this.renderMessageHistory()}
          {this.renderNoMessagesError()}
          {this.renderCanSendError()}
          <div ref={this.messagesEnd} />
        </div>
        <MessageBox
          loading={this.state.loader}
          send={this.send}
          canSend={this.canSend()}
          sms={this.state.sms_allowed}
          status={this.state.recipient.status}
          group={false}
          type={TEMP.EVACUEE}
        />
      </React.Fragment>
    );
  }
}

export default withRouter(EvacueeMessage);
