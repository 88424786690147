import React, { Component } from "react";

import BuildingTable from "./BuildingTable";

class BuildingTableList extends Component {
  renderBuildingsTable = () => {
    return !this.props.no_event
      ? this.props.buildings.map(bldg => {
          return (
            <BuildingTable
              building={bldg}
              loading={this.props.loading}
              key={bldg.id + " " + bldg.name}
            />
          );
        })
      : null;
  };

  renderSiteTotalTable = () => {
    return !this.props.no_event ? (
      <BuildingTable
        site_total={true}
        building={this.props.site_total}
        loading={this.props.loading}
      />
    ) : null;
  };

  render() {
    return (
      <React.Fragment>
        {this.props.loading ? (
          <React.Fragment>
            <div className="skeleton skeleton__table mt-10" />
            <div className="skeleton skeleton__table skeleton__table--alternate mt-20" />
          </React.Fragment>
        ) : (
          this.renderBuildingsTable()
        )}
        {/* {!this.props.loading ? this.renderSiteTotalTable() : null} */}
      </React.Fragment>
    );
  }
}

export default BuildingTableList;
