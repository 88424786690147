import React from "react";

const FloorStatusListItem = props => {
  const renderFloorEvacueeStatus = () => {
    let safe = props.floor.evacuees.safe;
    let total = props.floor.evacuees.total;

    let safeEvacueesBar = {
      width: (safe / total) * 100 + "%"
    };

    return (
      <React.Fragment>
        <div className="col s12 valign-wrapper">
          <div className="col s6">
          {props.floor.evacuees.safe + "/" + props.floor.evacuees.total}
          </div>
          <div className="col s3">
            {props.floor.evacuees.peep}
          </div>
          <div className="col s3">
            {props.floor.evacuees.refusal}
          </div>
        </div>
        <div className="col s12 floorstatuslistitem__evacuees">
          <div
            style={safeEvacueesBar}
            className="floorstatuslistitem__evacuees--safe"
          />
        </div>
      </React.Fragment>
    );
  };

  const getColor = () => {
    switch (props.floor.status.toLowerCase()) {
      case "update pending":
        return "floorstatus__label--lightgrey";
      case "floor evacuated":
        return "floorstatus__label--green";
      case "evacuating":
        return "floorstatus__label--orange";
      case "unable to access":
        return "floorstatus__label--red";
      case "shelter in place safe":
        return "floorstatus__label--green";
      case "shelter in place":
        return "floorstatus__label--red";
      case "shelter in place - breach":
        return "floostatus__label--red";
      default:
        return false;
    }
  };

  const renderFloorStatusLabel = () => {
    let className = "col s4 floorstatus__label";

    let color = getColor();
    if (color) {
      className = className + " " + color;
    }

    return <div className={className}>{props.floor.status}</div>;
  };

  return (
    <div className="row floorevacstatus__table">
      <div className="col s4 floorstatus__label">{props.floor.name}</div>
      {renderFloorStatusLabel()}
      <div className="col s4">{renderFloorEvacueeStatus()}</div>
    </div>
  );
};

export default FloorStatusListItem;
