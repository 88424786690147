import React, { Component } from "react";

import FilterControl from "./FilterControl";
import DisplayBar from "./DisplayBar";

import EvacueeList from "./EvacueeList";
import Loader from "../../Utilities/Loader";

import axios from "axios";

import {
  api_evacuee_safety_data,
  api_evacuee_safety_filtered,
  api_evacuee_safety_setStatus,
  api_evacuee_safety_floors_data,
  CancelTokenSource
} from "../../../config";

import "./scss/evacsaftey.scss";
import FloorSearch from "./FloorSearch";

export const AREA = {
  MYAREA: "my area",
  EVERYONE: "everyone",
  BUILDING: "building"
};

export const FILTER = {
  ASSIST: "assist",
  VISITOR: "visitor",
  REFUSAL: "refusal"
};

export const STATUS = {
  TOTAL: "total",
  SAFE: "safe",
  UNKNOWN: "unknown",
  DANGER: "danger"
};

export const MESSAGE = {
  SHOWALL: "show all",
  UNREAD: "unread",
  NOREPLY: "no reply"
};

class EvacueeSafety extends Component {
  state = {
    loading: false, // overall component loading
    loadSpinner: false, // loading overlay spinner
    lazyLoading: false, // check to see if something is lazyloading
    doLazyLoad: true, //only lazyload if this is true
    searchText: "",
    scrolling: false,
    sms_allowed: false,
    show: {
      area: AREA.MYAREA, //my area or everyone
      floors: ["all"],
      message: MESSAGE.SHOWALL, // assist or show all or visitor
      display: STATUS.TOTAL, //all or safe or unknown or danger
      assist: false,
      visitor: false,
      refusal: false
    },
    pagination: {
      // pagination details
      current_page: null,
      total_pages: null,
      next_page_url: null
    },
    evacuees: [], // array of all the paginated evacuees
    floors: [], // floors of the selected buildings
    filterCounts: {
      safe: 0,
      unknown: 0,
      danger: 0,
      total: 0
    },
    buildings: [],
    noEvent: false // check to see if there is an ongoing event
  };

  /**
   * This method sets the loading
   * state of the component to the
   * provided parameter
   */
  setLoading = loading => {
    this.setState({
      ...this.state,
      loading
    });
  };

  setSpinnerLoading = loadSpinner => {
    this.setState({
      ...this.state,
      loadSpinner
    });
  };

  componentDidMount() {
    window.addEventListener("scroll", () => {
      let focusedElement = window.document.activeElement;
      if (focusedElement === this.refs.searchInput) {
        this.refs.searchInput.blur();
      }
    });

    this.loadInitialState();
    this.loadFloorsData();

    /**
     * LazyLoad
     */
    this.initiateLazyLoad();
  }

  componentDidUpdate(prevProps, prevState) {
    /**
     * When any of the filters are updated
     */
    if (this.state.show !== prevState.show) {
      if (this.state.searchText.length >= 3) {
        this.loadFilteredData(true);
      } else {
        this.loadFilteredData();
      }
    }
    /**
     * When Search Text has been updated
     */
    if (this.state.searchText !== prevState.searchText) {
      if (this.state.searchText.length >= 3) {
        this.loadFilteredData(true);
      } else if (this.state.searchText.length === 0) {
        this.loadFilteredData(false);
      }
    }

    if (this.state.show.area !== prevState.show.area) {
      this.loadFloorsData();
    }
  }

  /**
   * Method to initiate LazyLoading
   */
  initiateLazyLoad = () => {
    window.onscroll = e => {
      if (
        window.innerHeight + window.pageYOffset >=
        document.body.offsetHeight - 150
      ) {
        this.lazyLoadEvacuees();
      }
    };
  };

  /**
   * This method is called when the component is mounted
   * It loads Initial State of this module
   */
  loadInitialState = () => {
    this.setLoading(true);
    axios
      .post(api_evacuee_safety_data, null)
      .then(res => {
        if (res.data.error === undefined) {
          this.setState({
            ...this.state,
            loading: false,
            evacuees: res.data.evacuees,
            pagination: res.data.pagination,
            filterCounts: res.data.filterCounts,
            buildings: res.data.buildings,
            noEvent: false,
            sms_allowed: res.data.sms_allowed
          });
        } else {
          this.setState({
            ...this.state,
            noEvent: true
          });
        }
      })
      .catch(err => {
        this.setLoading(false);
      });
  };

  /**
   * This method loads the floors data for selected building/area
   */

  loadFloorsData = () => {
    this.setSpinnerLoading(true);
    axios
      .get(api_evacuee_safety_floors_data, {
        cancelToken: CancelTokenSource.token,
        params: {
          area: this.state.show.area
        }
      })
      .then(res => {
        this.setState({
          ...this.state,
          floors: res.data.floors,
          floorsTotal: res.data.floorsTotal,
          loadSpinner: false
        });
      }).catch(err => {
        this.setSpinnerLoading(false);
      });
  };

  /**
   * This method loads the paginated evacuees,
   * it calls the state.pagination.next_url if
   * the current page != last page
   */
  lazyLoadEvacuees = () => {
    if (
      this.state.pagination !== null &&
      this.state.pagination.next_page_url !== null &&
      !this.state.lazyLoading &&
      this.state.doLazyLoad
    ) {
      let params = null;
      if (this.state.searchText.length >= 3) {
        params = {
          ...this.state.show,
          search: this.state.searchText
        };
      } else {
        params = { ...this.state.show };
      }

      this.setState(
        {
          ...this.state,
          lazyLoading: true
        },
        () => {
          axios
            .post(this.state.pagination.next_page_url, params)
            .then(resp => {
              let newEvacuees = [...this.state.evacuees];
              newEvacuees.push(...resp.data.evacuees);
              this.setState({
                ...this.state,
                evacuees: newEvacuees,
                pagination: {
                  next_page_url: resp.data.pagination.next_page_url,
                  from: resp.data.pagination.from,
                  total: resp.data.pagination.total
                },
                filterCounts: resp.data.filterCounts,

                lazyLoading: false
              });
            })
            .catch(err => {
              this.setState({
                ...this.state,
                lazyLoading: false
              });
              console.log(err);
            });
        }
      );
    }
  };

  /**
   * This method sends a request to the server
   * along with the filters to recieve a paginated
   * respose to lazyload
   */

  loadFilteredData = (withSearch = false) => {
    // this.setSpinnerLoading(true);
    let params = null;
    if (withSearch) {
      params = {
        ...this.state.show,
        search: this.state.searchText
      };
    } else {
      params = { ...this.state.show };
    }

    axios
      .post(
        api_evacuee_safety_filtered,
        params)
      .then(res => {
        if (res.data.error === undefined) {
          this.setState({
            ...this.state,
            // loadSpinner: false,
            evacuees: res.data.evacuees,
            pagination: res.data.pagination,
            filterCounts: res.data.filterCounts
          });
        } else {
          if (res.data.error === "no_evacuees") {
            this.setState({
              ...this.state,
              // loadSpinner: false,
              evacuees: [],
              pagination: null
            });
          }
        }
      })
      .catch(err => {
        // this.setSpinnerLoading(false);x`

        console.log(err);
      });
  };

  /**
   * This method render the lazyload information
   * it checks if it is the last page or if we
   * are already lazy loading and provies
   * information
   */
  renderLazyLoadInfo = () => {
    let last = false;
    let loading = false;
    if (
      this.state.pagination !== null &&
      this.state.pagination.next_page_url === null
    ) {
      last = true;
    }
    if (this.state.lazyLoading) {
      loading = true;
    }

    return (
      <React.Fragment>
        {last ? (
          <div className="container pb-10">
            <p className="center-align grey-text text-lighten-1">
              <b>You have reached the end.</b>
            </p>
          </div>
        ) : null}
        {loading ? (
          <div className="container pb-10">
            <p className="center-align grey-text text-lighten-1">
              <b>Loading...</b>
            </p>
          </div>
        ) : null}
      </React.Fragment>
    );
  };

  setFilter = (obj, value) => {
    this.setState({
      ...this.state,
      show: {
        ...this.state.show,
        [obj]: value
      }
    });
  };

  /**
   * Method is called when anything is
   * changed in the search text field
   */
  updateSearchText = e => {
    this.setState({
      ...this.state,
      searchText: e.target.value
    });
  };

  /**
   * Changes the state display value
   * to ALL || SAFE || UNKNOWN || DANGER
   */
  toggleDisplay = value => {
    if (this.state.show.display !== value) {
      this.setState({
        ...this.state,
        show: {
          ...this.state.show,
          display: value
        }
      });
    }
  };

  setStatus = (status, evacueeId) => {
    // this.setSpinnerLoading(true);

    axios
      .get(api_evacuee_safety_setStatus, {
        params: {
          evacueeId: evacueeId,
          status: status
        }
      })
      .then(res => {
        // this.setSpinnerLoading(false);
        if (res.data.error === undefined) {
          if (this.state.searchText.length >= 3) {
            this.loadFilteredData(true);
          } else {
            this.loadFilteredData(false);
          }
        }
      })
      .catch(err => {
        this.setSpinnerLoading(false);
      });
  };

  setSafe = evacueeId => {
    let evacuee = this.state.evacuees.filter(evacuee => {
      return evacuee.id === evacueeId;
    })[0];
    if (evacuee.status !== "safe") {
      this.setStatus("safe", evacueeId);
    }
  };

  setDanger = evacueeId => {
    let evacuee = this.state.evacuees.filter(evacuee => {
      return evacuee.id === evacueeId;
    })[0];

    if (evacuee.status !== "danger") {
      this.setStatus("in danger", evacueeId);
    }
  };

  setUnknown = evacueeId => {
    let evacuee = this.state.evacuees.filter(evacuee => {
      return evacuee.id === evacueeId;
    })[0];

    if (evacuee.status !== "unknown") {
      this.setStatus("unknown", evacueeId);
    }
  };

  renderFilterControls = () => {
    if (!this.state.loading) {
      return (
        <FilterControl
          buildings={this.state.buildings}
          show={this.state.show}
          setFilter={this.setFilter}
          filters={this.state.show}
          searchText={this.state.searchText}
          sms_allowed={this.state.sms_allowed}
          evacuees={this.state.evacuees}
        />
      );
    } else {
      return (
        <div className="container">
          <div className="skeleton skeleton__field br-10 mt-10" />
        </div>
      );
    }
  };

  renderDisplayBar = () => {
    if (!this.state.loading) {
      return (
        <DisplayBar
          showDisplay={this.state.show.display}
          toggleDisplay={this.toggleDisplay}
          counts={this.state.filterCounts}
        />
      );
    } else {
      return (
        <div className="container">
          <div className="skeleton skeleton__field br-10 mt-10" />
        </div>
      );
    }
  };

  clearSearch = () => {
    this.setState({
      ...this.state,
      searchText: ""
    });
  };

  disableSearchClear = () => {
    if (this.state.searchText.length === 0) {
      return true;
    }
    return false;
  };

  isFloorSelected = floorId => {
    if (this.state.show.floors.includes("all")) {
      return true;
    }

    if (this.state.show.floors.includes(floorId)) {
      return true;
    }
    return false;
  };

  handleFloorSelectionChange = floor => {
    let newArr = [...this.state.show.floors];
    let index = newArr.indexOf(floor);
    if (index > -1) {
      newArr.splice(index, 1);
    } else {
      if (newArr.includes("all")) {
        newArr = [];
        this.state.floors.forEach(item => {
          item.floors.forEach(flr => {
            if (floor !== flr.id.toString()) {
              newArr.push(flr.id.toString());
            }
          });
        });
      } else {
        if (floor === "all") {
          newArr = ["all"];
        } else {
          newArr.push(floor);
        }
      }
    }

    if (newArr.length === this.state.floorsTotal) {
      newArr = ["all"];
    }

    this.setState({
      ...this.state,
      show: {
        ...this.state.show,
        floors: newArr
      }
    });
  };

  renderSearchBar = () => {
    if (!this.state.loading) {
      return (
        <FloorSearch
          searchText={this.state.searchText}
          updateSearchText={this.updateSearchText}
          clearSearch={this.clearSearch}
          disableSearchClear={this.disableSearchClear}
          floors={this.state.floors}
          isFloorSelected={this.isFloorSelected}
          handleFloorSelectionChange={this.handleFloorSelectionChange}
          selectedFloors={this.state.show.floors}
        />
      );
    } else {
      return (
        <div className="container">
          <div className="skeleton skeleton__field br-10 mt-10" />
        </div>
      );
    }
  };

  renderNoEvacueeError = () => {
    if (
      !this.state.loading &&
      !this.state.loadSpinner &&
      this.state.evacuees.length === 0
    ) {
      return (
        <div className="container pb-10">
          <p className="center-align grey-text text-lighten-1">
            <b>No Evacuees found with the selected filters</b>
          </p>
        </div>
      );
    }
  };

  renderEvacueeList = () => {
    if (!this.state.loading) {
      return (
        <EvacueeList
          evacuees={this.state.evacuees}
          setSafe={this.setSafe}
          setDanger={this.setDanger}
          setUnknown={this.setUnknown}
          handleSpecialClick={this.handleSpecialClick}
        />
      );
    } else {
      return (
        <div className="container">
          <div className="skeleton skeleton__table br-10 mt-10" />
          <div className="skeleton skeleton__table br-10 mt-10" />
        </div>
      );
    }
  };

  renderIfEvent = () => {
    if (!this.state.noEvent) {
      return (
        <div className="evacsaftey">
          {this.renderFilterControls()}
          {this.renderDisplayBar()}
          {this.renderSearchBar()}
          {this.renderEvacueeList()}
          {this.renderNoEvacueeError()}
          {!this.state.loading ? this.renderLazyLoadInfo() : null}
        </div>
      );
    } else {
      return "NO EVENT";
    }
  };

  render() {
    return (
      <React.Fragment>
        <Loader loading={this.state.loadSpinner} />
        {this.renderIfEvent()}
      </React.Fragment>
    );
  }
}

export default EvacueeSafety;
